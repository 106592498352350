import axios from 'axios';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import {
    MaterialReactTable,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import {ProctorCompactionTest} from './data-definition'
import MaterialTypeToggleButtonGroup from './material-type-toggle-button-group'

interface CreateModalProps {
    columns: MRT_ColumnDef<ProctorCompactionTest>[];
    onClose: () => void;
    onSubmit: (values: ProctorCompactionTest) => void;
    open: boolean;
    tableData: ProctorCompactionTest[];
  }
  
  //example of creating a mui dialog modal for creating new rows
  export const CreateNewTestModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    tableData,
  }: CreateModalProps) => {

    const noVisibleColumns = ["_id", "testType"]
    // List of keys for which TextField should be required
    const requiredKeys = ['testID', 'materialType', 'maxDryDensity', 'optimumMoistureContent', 'wetDensity']

    const [values, setValues] = useState<any>(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        // if (column.accessorKey !== '_id') {
        //   acc[column.accessorKey ?? ''] = '';
        // }
        return acc;
      }, {} as any),
    );
  
    const [errors, setErrors] = useState({});
  
    useEffect(() => {
      if ('maxDryDensity' in values && 'optimumMoistureContent' in values) {
        const maxDryDensity = Number(values.maxDryDensity);
        const optimumMoistureContent = Number(values.optimumMoistureContent);
    
        const wetDensity = (1 + optimumMoistureContent / 100) * maxDryDensity;
        const roundedWetDensity = wetDensity.toFixed(1);
    
        setValues({
          ...values,
          'wetDensity': roundedWetDensity,
        });
      }
    }, [values.maxDryDensity, values.optimumMoistureContent]);
  
    const handleSubmit = async () => {
  
      //put your validation logic here
      let newErrors = {};
      
      columns.forEach((column) => {
        if (requiredKeys.includes(column.accessorKey)) {
          if (column.accessorKey && (values[column.accessorKey] === undefined || values[column.accessorKey] === '')) {
            newErrors[column.accessorKey] = 'Required';
          }          
        }

      });
  
      // Check if 'testID' already exists in 'tableData'
      if ((tableData as any).some((row: any) => row.testID?.toLowerCase() === values.testID?.toLowerCase())) {
        newErrors["testID"] = 'This test ID already exists';
      }
  
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
  
      console.log(values)
      // Continue with form submission
      onSubmit(values);
      setValues({})
      onClose();
    };

    const handleCancel = async () => {
      onClose();
      setErrors({})
    }
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Test</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            justifyContent="center"
            // alignItems="center" 
            className="my-1 py-1"
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) => {

                if (!noVisibleColumns.includes(column.accessorKey)) {
                // List of keys for which TextField type should be "number"
                const numberKeys = ['maxDryDensity', 'optimumMoistureContent', 'wetDensity'];
                // List of keys for which TextField should be disabled
                const disabledKeys = ['wetDensity'];
                // List of keys for which TextField should be multilines
                const multilineKeys = ['notes']
    
                // If column.accessorKey is in numberKeys, TextField type will be "number". Otherwise, it will be "text".
                const inputType = numberKeys.includes(column.accessorKey) ? 'number' : 'text';
                // If column.accessorKey is in requiredKeys, TextField will be required
                const isRequired = requiredKeys.includes(column.accessorKey);
                // If column.accessorKey is in disabledKeys, TextField will be disabled
                const isDisabled = disabledKeys.includes(column.accessorKey);
                // If column.accessorKey is in multilineKeys, TextField will be disabled
                const isMultiline = multilineKeys.includes(column.accessorKey);    
                return (
                  column.accessorKey === 'materialType'
                  ?
                  <FormControl 
                  fullWidth 
                  required
                  key = {column.accessorKey + "-form-control"}
                  >
                  <MaterialTypeToggleButtonGroup
                    values = {values}
                    setValues={setValues}
                    // confirmButtonText = {confirmButtonText}                
                  />
                  {Boolean(errors[column.accessorKey]) && <FormHelperText error>{errors[column.accessorKey]}</FormHelperText>}
                  </FormControl>
                  :    
                  <FormControl
                  // required
                  fullWidth
                  key = {column.accessorKey + "-form-control"}
                  >  
                    <TextField
                      required = {isRequired}
                      fullWidth
                      disabled={isDisabled}
                      multiline = {isMultiline}
                      value={values[column.accessorKey] || ''}
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      type={inputType}
                      onChange={(e) => {
                        // // convert to float
                        // const value = ['maximumDryDensity', 'optimumMoistureContent', 'wetDensity'].includes(e.target.name)
                        // ? parseFloat(e.target.value)
                        // : e.target.value;
                        // save input data
                        setValues({ ...values, [e.target.name]: e.target.value });
                        if (errors[e.target.name]) {
                          // If there's an error for this field, clear it when the field is modified
                          setErrors({ ...errors, [e.target.name]: null });
                        }
                      }}
                      error={Boolean(errors[column.accessorKey])}
                      helperText={errors[column.accessorKey]}
                    />               
                  </FormControl>
                  
                  )
                } 
  
                  })}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button color="inherit" onClick={handleCancel}>Cancel</Button>
          <Button color="success" onClick={handleSubmit} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  };