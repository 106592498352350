import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function MapComponentToggleGroup() {
  const [alignment, setAlignment] = React.useState(() => ['grid200', "grid100","imagery","preloadingCells"]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string[],
  ) => {
    setAlignment(newAlignment);
    localStorage.setItem("mapComponentActiveList", JSON.stringify(newAlignment));

  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      onChange={handleChange}
      aria-label="Platform"
      id="map-component-toggle-group"
    >
      <ToggleButton value="grid200">G200</ToggleButton>
      <ToggleButton value="grid100">G100</ToggleButton>
      <ToggleButton value="imagery">Imagery</ToggleButton>
      <ToggleButton value="preloadingCells">Preload</ToggleButton>
    </ToggleButtonGroup>
  );
}