import * as React from 'react';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useCallback, useMemo, useState, useEffect } from 'react';

import {ToggleButton, ToggleButtonGroup} from '@mui/material'

interface SetValuesProps {
    values: any;
    setValues: React.Dispatch<React.SetStateAction<any>>;
    // confirmButtonText: string;
  }

export default function MaterialTypeToggleButtonGroup(props: SetValuesProps) {
  const {
    values, 
    setValues, 
    // confirmButtonText,
  } = props;
  // console.log(values);
  const [alignment, setAlignment] = React.useState(values['materialType'] ?? '');

  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');



  // When the value in the parent component changes, update the local state
  useEffect(() => {
    setAlignment(values['materialType'] );
  }, [values['materialType']]);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setValues(prevValues => ({ ...prevValues, materialType: newAlignment }));
};

  return (
    <ToggleButtonGroup
        size="large"
        color="primary"
        value={alignment}
        // disabled={confirmButtonText === "Create" ? false : true}
        exclusive
        onChange={handleChange}
        aria-label="test-type-toggle-button-group"
    >
      <ToggleButton 
        value="Structure Fill"
        color="secondary"
        >
          Structure Fill
      </ToggleButton>
      <ToggleButton 
        value="Soil Cover"
        color="success"
        >
          Soil Cover
      </ToggleButton>
      <ToggleButton 
        value="CCR"
        color="info"
        >
        CCR
      </ToggleButton>
    </ToggleButtonGroup>
  );
}