import axios from 'axios';
import { useCallback, useMemo, useState, useEffect, } from 'react';

import * as React from 'react';

import { styled } from '@mui/material/styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,

} from '@mui/material';


import CloseIcon from '@mui/icons-material/Close';



import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useMyStateHook } from '../fill_grid_inputs/use-mystate-hook';

import LoadingComponent from '../loading-page'

import ProctorInputTable from './proctor-input-table'


interface SetValuesProps {
    showDialog: any;
    setShowDialog: React.Dispatch<React.SetStateAction<any>>;
  }

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}



export default function ProctorInputDialog(props: SetValuesProps) {

    const theme = useTheme();
    const fullScreenDialog = useMediaQuery(theme.breakpoints.down('xl'));

    const {showDialog, setShowDialog} = props;
    const [isLoading, setIsLoading] = useState(false);

    const handleGridInputModalClose= () => {
        setShowDialog(false)
    };

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
        },
    }));
    
    function BootstrapDialogTitle(props: DialogTitleProps) {
        const { children, onClose, ...other } = props;
    
        return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            ) : null}
        </DialogTitle>
        );
    }

    return (

      <div>

        <BootstrapDialog
        onClose={handleGridInputModalClose}
        aria-labelledby="proctor-input-dialog"
        open={showDialog}
        fullScreen={fullScreenDialog}
        maxWidth='xl'
      >
        <BootstrapDialogTitle id="proctor-input-dialog-title" onClose={handleGridInputModalClose}>
          Proctor Compaction Tests
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ProctorInputTable
            showDialog = {showDialog}
          />

        </DialogContent>


        <DialogActions>
          <Button autoFocus onClick={handleGridInputModalClose} color='inherit'>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

      {/* {isLoading && (
        <LoadingComponent/>
      )} */}
      </div>





    )

}


