import { useEffect, useRef } from 'react';
import {useNavigate} from "react-router-dom";

const TIMEOUT = 30 * 60 * 1000; // 30 minutes

const useAutoLogout = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    document.onclick = resetTimer;
    startTimer();
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const startTimer = () => {
    timerRef.current = setTimeout(logout, TIMEOUT);
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      startTimer();
    }
  };

  const navigate = useNavigate();

  const logout = () => {
    // // perform logout here
    // fetch('http://your-fastapi-endpoint/logout', {
    //   method: 'POST',
    //   credentials: 'include', // to include the cookies
    //   // include headers, body etc. according to your logout API
    // }).then(res => {
    //   if (res.ok) {
    //     // handle successful logout, for example:
    //     window.location.href = '/login';
    //   }
    // });
    localStorage.removeItem('GSToken')
    navigate("/login");
  };
};

export default useAutoLogout;