import {useState} from 'react'
import {setToken, fetchToken} from '../functions/Auth'
import {useNavigate} from "react-router-dom"
import axios from 'axios';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import MainPage from './main_page'
import LoadingPage from '../components/loading-page'


// import CompanyLogo from '../assets/company-logo.png'
const CompanyLogoURL = require('../assets/company-logo.png')

// const axios = require('axios')

export default function Login(){
    const appName = process.env.REACT_APP_NAME 
    const [isLoading, setIsLoading] = useState(false);   
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const login = () =>{
        if(username === '' && password === ''){
            return
        }else{
            // console.log('axios')
            setIsLoading(true);
            axios.post(process.env.REACT_APP_BACKEND_URL + '/login', {
                username: username,
                password: password
            })
            .then(function (response) {
                if(response.data.token){
                    
                    setToken(response.data.token)
                    navigate("/main");
                    localStorage.setItem('Username', username);

                }
            })
            .catch(function (error) {
                console.log(error, 'error');
            })
            .then(function () {
                setIsLoading(false);
            });
        }
       
    }
    return(
        <>
            <div 
            style = {{
                minHeight: 800, 
                // marginTop: 20,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",                
             }}
            >
                <>
                    {/* <h1>Home Page</h1> */}
                    <div 
                    style = {{margin: 'auto'}} 
                    >
                        {
                            fetchToken() 
                            ? (<MainPage/>)
                            : (
                                isLoading
                                ? (<LoadingPage/>)
                                :
                                <Card 
                                // className="m-2 p-1"
                                style={{
                                    "display": "block",
                                    'maxWidth':'600px',
                                    'margin':'auto',
                                    // 'paddingTop':'50px',
                                    }}                                
                                >
                                    <Card.Header
                                        style={{
                                            'borderRadius': '0px 0px 0px 0px',
                                            'backgroundColor': '#FAFAFA',
                                            'color':'#6D6E71',                                                      
                                            }}                                    
                                    >
                                    <Col 
                                    xs={12}
                                    style={{
                                        maxWidth:300,
                                        margin:'auto',
                                        paddingBottom:0,
                                    }}
                                    className="mb-0"
                                    >
                                        <img src= {CompanyLogoURL} width="300" alt="CompanyLogo"/>

                                    </Col>
                                    <Col><hr/></Col>                                        
                                        <Col>
                                        <h4 
                                        className="card-title"
                                        style={{
                                            'maxWidth':'500px',
                                            'margin':'auto',
                                            'paddingTop':'00px',
                                            'paddingBottom':'10px',
                                            }}                                          
                                        > {appName}
                                        </h4>
                                    </Col>                                        
                                    </Card.Header>
                                    <Card.Body>
                                        <Form>
                                        <Form.Floating className="mb-1">
                                        <Form.Control
                                            id="login-username-input"
                                            type="text"
                                            placeholder="Enter Username"
                                            onChange={ (e)=> setUsername(e.target.value)}
                                        />
                                        <label htmlFor="login-username-input">Username</label>
                                        </Form.Floating>
                                        <Form.Floating className="mb-1">
                                        <Form.Control
                                            id="login-password-input"
                                            type="password"
                                            placeholder="Enter Password"
                                            onChange={ (e)=> setPassword(e.target.value)}
                                        />
                                        <label htmlFor="login-password-input">Password</label>
                                        </Form.Floating>
                                        <div className="d-grid gap-2 mb-1">
                                            <Button 
                                            variant="primary" 
                                            // type="submit"
                                            id="login-confirm-button"
                                            size="sm"
                                            onClick = {login}
                                            >
                                            Confirm
                                            </Button>
                                        </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                                
                            )
                        }
                    
                    </div>
                </>
            </div>
            
        </>
    )
}