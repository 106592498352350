import * as React from 'react';
import axios from 'axios';
import { openDB } from 'idb';

import { 
    useState, 
    useCallback,
    useMemo,
    useEffect, 
  } from 'react';

import Map, {
  Source,
  Layer,

  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,

} from 'react-map-gl';
import type {SkyLayer} from 'react-map-gl';
import {useControl} from 'react-map-gl';

import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
// https://deck.gl/docs/api-reference/mapbox/mapbox-overlay#using-with-react-map-gl
// https://deck.gl/docs/get-started/using-with-typescript
import {
  BitmapLayer,
  PathLayer,
  TextLayer,
  GridCellLayer,
  ScatterplotLayer,
  PolygonLayer,
} from '@deck.gl/layers/typed';

import {GridLayer} from '@deck.gl/aggregation-layers/typed';
import {COORDINATE_SYSTEM} from '@deck.gl/core/typed';

import { 
  Modal, 
  // Button 
} from 'react-bootstrap';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useMyStateHook } from '../fill_grid_inputs/use-mystate-hook';

import { getValuesByLiftNo, getLatestSurveyResult, passOrFailSelection } from '../fill_grid_inputs/grid-CQA-input-default-inputs'


import FillGridCQAInputTable from '../fill_grid_inputs/grid-CQA-input-table'
import ExcaGridCQAInputTable from '../exca_grid_inputs/grid-CQA-input-table'

import LocationIDAutocompleteSelect from './location-id-autocomplete-select'
import MapComponentToggleGroup from './map-component-toggle-group'
import MapColorLegend from './map-color-legend'

import MapLoadingComponent from './map-loading-component'

// import MapStyleSelect, {Mode} from './map-style-select';

import PreloadCellLayer from '../../data/layers/Cell_Layer_DK.json';
// import Backfill200Layer from '../../data/layers/grids/GRID200.json';
// import Excav100Layer from '../../data/layers/grids/GRID100.json';

const ImageryLayerURL = require('../../data/layers/Barry April2023-transparent.png')

// DeckGLOverlay:
function DeckGLOverlay(props: MapboxOverlayProps & {
  interleaved?: boolean;
}) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
}

// grid-CQA-input-dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export function getLatestCQADataArray(array: any): any {

  return array.reduce((result, item) => {
    let latestCQAData: any = null;

    if (item.CQAData && item.CQAData.length > 0) {
      // // Sorting the CQAData array by date and returning the latest date item
      // latestCQAData = item.CQAData.sort((a, b) => b.date.localeCompare(a.date))[-1];
      // Finding the latest date
      const latestDate = item.CQAData.reduce((maxDate, data) => (data.date > maxDate ? data.date : maxDate), '');

      // Finding the last item with the latest date
      // latestCQAData = item.CQAData.filter((data) => data.date === latestDate).slice(-1)[0];
      latestCQAData = item.CQAData.find((obj: any) => obj.surveyElevation === item.elevation)
    }

    // Using the "_id" as the key and assigning the corresponding value
    result[item._id] = latestCQAData;
    
    return result;
  }, {} as { [key: string]: any});

}

export default function MainMapView() {

  const [isLoading, setIsLoading] = useState(false);
  const [currentGridData, getCurrentGridData] = useState({});
  const [currentGridID, getCurrentGridID] = useState("");
  const [currentGridType, getCurrentGridType] = useState("");

  const handleGridOnClick = (object:any) => {
    getCurrentGridID(object["_id"])
    // console.log(object)

    if (object.hasOwnProperty('CQAData')) {

      var filteredCQAData= object.CQAData.map(obj => {
        // Creating a new object to avoid mutating the original data
        var newObj = {};
        for (var key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] !== "" && obj[key] !== null) {
            newObj[key] = obj[key]; // Copy over non-empty values
          }
        }
        return newObj; // Return the new object with empty string values removed
      });
      object.CQAData = filteredCQAData
    }

    // console.log(object)
    getCurrentGridData(object)
    getCurrentGridType(object["locationType"])

    // show Grid input modal:
    handleGridInputModalShow()
  };

  
// load grid points data from mongodb:
  const [Backfill200Layer, getBackfill200Layer] = useState([]);
  const [Excav100Layer, getExcav100Layer] = useState([]);

  const [latestCQAData100Layer, getLatestCQAData100LayerLayer] = useState([]);
  const [latestCQAData200Layer, getLatestCQAData200LayerLayer] = useState([]);

  const [SearchGridIDList, getSearchGridIDList] = useState([]);

  const [G100LayerOpacity, getG100LayerOpacity] = useState(0.4);
  const [G200LayerOpacity, getG200LayerOpacity] = useState(0.4);
  const [imageryLayerOpacity, getImageryLayerOpacity] = useState(1);
  const [preloadLayerOpacity, getPreloadLayerOpacity] = useState(0.4);

  const [G100LayerVisible, getG100LayerVisible] = useState(true);
  const [G200LayerVisible, getG200LayerVisible] = useState(true);
  const [imageryLayerVisible, getImageryLayerVisible] = useState(true);
  const [preloadLayerVisible, getPreloadLayerVisible] = useState(true);

//   async function getAllGridDataFromURL() {
//     setIsLoading(true);

//     const API_URL = "/cqa_data/points"


//     axios.get(process.env.REACT_APP_BACKEND_URL + API_URL).then(function (response) {
//       // handle success
//       console.log("Successfully loaded data!");

//       const grid100LayerData = response.data.filter((item:any) => item["locationType"] === "Grid 100")
//       const grid200LayerData = response.data.filter((item:any) => item["locationType"] === "Grid 200")



//       // getGridPointData(response.data)
//       getBackfill200Layer(grid200LayerData)
//       getExcav100Layer(grid100LayerData)

//       const targetObject = grid200LayerData.find(item => item._id === "K11-Grid-200-1812600.9-364502.0-165");
//     console.log("targetObject", targetObject)

// // Latest CQA data:
// // latestCQAData200Layer:
//       const latestCQAData200Layer = getLatestCQADataArray(grid200LayerData)
//       console.log(latestCQAData200Layer)
//       getLatestCQAData200LayerLayer(latestCQAData200Layer)
// // latestCQAData100Layer:
//       const latestCQAData100Layer = getLatestCQADataArray(grid100LayerData)
//       // console.log(latestCQAData100Layer)
//       getLatestCQAData100LayerLayer(latestCQAData100Layer)



// // getSearchGridIDList
//       getSearchGridIDList(response.data.map(({ _id, locationID, latitudeDecimal, longitudeDecimal }: { _id: string, locationID: string, latitudeDecimal: number, longitudeDecimal: number }) => ({ _id, locationID, latitudeDecimal, longitudeDecimal })))
// // getSearchLiftNoList
//       const uniqueValues: string[] = [];
//       response.data.forEach((item:any) => {
//         if (item.CQAData && item.CQAData.length >0 ) {
//           // console.log(item.CQAData)
//           item.CQAData.forEach((dataItem:any) => {
//             if (!uniqueValues.includes(dataItem.liftNo)) {
//               // console.log(dataItem.liftNo)
//               uniqueValues.push(dataItem.liftNo);
//             }
//           });
//         }
//       });
//       // getSearchLiftNoList(uniqueValues);
//     })
//     .catch(function (error) {
//       // handle error
//       console.log(error);
//     })
//     .then(function () {
//       // always executed
//       setIsLoading(false);

//     });
//   }

  async function getAllGridDataFromURL() {
    setIsLoading(true);
    const db = await openDB('myDB', 1, {
      upgrade(db) {
        db.createObjectStore('gridData');
      },
    });
  
    const API_URL = process.env.REACT_APP_CQA_POINTS_URL;
    let data;
  
    if (navigator.onLine) {
      // Online: Load data from cloud
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + API_URL);
      data = response.data;
  
      // Store response in IndexedDB
      const tx = db.transaction('gridData', 'readwrite');
      await tx.store.put(data, API_URL);
      await tx.done;
    } else {
      // Offline: Load response from IndexedDB
      // data = await db.get('gridData', API_URL);
    }
  
    console.log("Successfully loaded data!");
  
    // Filter the data only once
    const grid100LayerData = [];
    const grid200LayerData = [];
  
    data.forEach((item: any) => {
      if (item["locationType"] === "Grid 100") {
        grid100LayerData.push(item);
      } else if (item["locationType"] === "Grid 200") {
        grid200LayerData.push(item);
      }
    });
  
    getBackfill200Layer(grid200LayerData);
    getExcav100Layer(grid100LayerData);
  
    // Latest CQA data:
    const latestCQAData200Layer = getLatestCQADataArray(grid200LayerData);
    console.log(latestCQAData200Layer);
    const latestCQAData100Layer = getLatestCQADataArray(grid100LayerData)
    getLatestCQAData100LayerLayer(latestCQAData100Layer)

    // getSearchGridIDList
    const searchGridIDList = data.map(({ _id, locationID, latitudeDecimal, longitudeDecimal }: { _id: string, locationID: string, latitudeDecimal: number, longitudeDecimal: number }) => ({ _id, locationID, latitudeDecimal, longitudeDecimal }));
    console.log(searchGridIDList)
    getSearchGridIDList(searchGridIDList)

    setIsLoading(false);
  }






// Call the function when the page loads
  window.onload = function() {
    // getAllGridDataFromURL();
    // getLiftNoSelection("1");
    getSearchGridIDList([]);
    // localStorage.setItem("mapComponentActiveList", JSON.stringify(["grid200", "grid100", "imagery", "preloadingCells"]));
    getImageryLayerOpacity(0.4);
    getPreloadLayerOpacity(0.4);
    getG100LayerOpacity(0.4);
    getG200LayerOpacity(0.4);
 
    getImageryLayerVisible(true);
    getPreloadLayerVisible(true);
    getG100LayerVisible(true);
    getG200LayerVisible(true);

 
  };

// Update Map based on liftNO selection:
  const LiftNoSelectNode = document.getElementById('liftNo-autocomplete-select');
  if (LiftNoSelectNode) {
    const observer = new MutationObserver((mutations) => {
      const currentSelectedLiftNumber = localStorage.getItem("currentSelectedLiftNumber")
      if (currentSelectedLiftNumber) {
        // getLiftNoSelection(currentSelectedLiftNumber)
        // console.log(currentSelectedLiftNumber);
        // getAllGridDataFromURL()

        }
      // mutations.forEach((mutation) => {
      //   console.log('The component was updated:', mutation);
      // });  

      })

    observer.observe(LiftNoSelectNode, { attributes: true, childList: false, subtree: false });
  }

  useEffect(() => {
    getAllGridDataFromURL()
  }, 
  [])

// load map:
  let MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

  const skyLayer: SkyLayer = {
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'atmosphere',
      'sky-atmosphere-sun': [0.0, 0.0],
      'sky-atmosphere-sun-intensity': 15
    }
  };

  // const [mode, setMode] = useState<Mode>('satellite-streets');

  // const map_style = useMemo(() => {
  //   if (mode === 'satellite-streets') {
  //     return "mapbox://styles/mapbox/satellite-streets-v9"
  //   } else if (mode === 'satellite') {
  //     return "mapbox://styles/mapbox/satellite-v9"
  //   }
  
  // }, [mode]);

  const [viewState, setViewState] = useState({
    latitude: 31,
    longitude: -87.996,
    zoom: 14,
    bearing: 0,
    pitch: 0,
  });

  const LocationIDSelectNode = document.getElementById('location-id-autocomplete-select');
  if (LocationIDSelectNode) {
    const observer = new MutationObserver((mutations) => {
      const longitudeDecimalView = localStorage.getItem("longitudeDecimalView")
      const latitudeDecimalView = localStorage.getItem("latitudeDecimalView")
      if (latitudeDecimalView && longitudeDecimalView) {
        const latitude = parseFloat(latitudeDecimalView);
        const longitude = parseFloat(longitudeDecimalView);
        setViewState({
          latitude: latitude,
          longitude: longitude,
          zoom: 19,
          bearing: 0,
          pitch: 0,
        })

      }
      // mutations.forEach((mutation) => {
      //   console.log('The component was updated:', mutation);
      // });

    });
    observer.observe(LocationIDSelectNode, { attributes: true, childList: false, subtree: false });
  }


  const MapComponentToggleGroupNode = document.getElementById('map-component-toggle-group');
  if (MapComponentToggleGroupNode) {
    const observer = new MutationObserver((mutations) => {
      const temp = localStorage.getItem("mapComponentActiveList")
      const mapComponentActiveList = temp ? JSON.parse(temp) : [];

      if (mapComponentActiveList !== null) {
        if (mapComponentActiveList.includes("imagery")) {
          getImageryLayerVisible(true)
          // getImageryLayerOpacity(0.7)
        } else {
          getImageryLayerVisible(false)
          // getImageryLayerOpacity(0.)

        }
        if (mapComponentActiveList.includes("preloadingCells")) {
          getPreloadLayerVisible(true)
          // getPreloadLayerOpacity(0.4)
        } else {
          getPreloadLayerVisible(false)
          // getPreloadLayerOpacity(0.)
        }
        if (mapComponentActiveList.includes("grid100")) {
          getG100LayerVisible(true)
          // getG100LayerOpacity(0.4)
        } else {
          getG100LayerVisible(false)
          // getG100LayerOpacity(0.)
        }
        if (mapComponentActiveList.includes("grid200")) {
          getG200LayerVisible(true)
          // getG200LayerOpacity(0.4)
        } else {
          getG200LayerVisible(false)
          // getG200LayerOpacity(0.)
        }
      }
      else {
        getG100LayerOpacity(0.4)
      }


    });
    observer.observe(MapComponentToggleGroupNode, { attributes: false, childList: true, subtree: true });
  }

  const imagery_layer = new BitmapLayer({
    id: 'imagery-layer',
    // [left, bottom, right, top]
    bounds: [
      -88.006918,
      30.989595,
      -87.986797,
      31.009257,
  ],
    image: ImageryLayerURL,
    transparentColor: [0, 0, 0, 0],
    opacity: imageryLayerOpacity,
    visible: imageryLayerVisible,
    // https://deck.gl/docs/developer-guide/tips-and-tricks#z-fighting-and-depth-testing
    parameters: {
        "depthTest": false,
        },
  });


  const PreloadCellPathLayer = new PathLayer({
    id: 'preload-cell-path-layer',
    data: PreloadCellLayer,
    pickable: true,
    widthScale: 1,
    widthMinPixels: 2,
    getPath: d => d.path,
    getColor: d => [255, 255, 255],
    getWidth: d => 5,
    opacity: preloadLayerOpacity,
    visible: preloadLayerVisible,

  });

  const PreloadCellTextLayer = new TextLayer({
    id: 'preload-cell-text-layer',
    data: PreloadCellLayer,
    pickable: true,
    getPosition: d => d.coordinates,
    getText: d => d.id,
    getColor: d => [255, 255, 255],
    opacity: preloadLayerOpacity,
    visible: preloadLayerVisible,
    getSize: 18,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center'
  });


  const Excav100GridLayer = new GridCellLayer({
    id: 'excav-100-grid-layer',
    data: Excav100Layer,
    pickable: true,
    extruded: true,
    cellSize: 100*0.3048,
    offset: [0, 0],
    coverage:0.97,
    elevationScale: 1,
    getPosition: d => [d["longitudeDecimal"], d["latitudeDecimal"]],
    getElevation: d => 
    d["elevation"] 
    && d["elevation"]  !== undefined
    ? Math.abs(d["elevation"])
    : 0.1,
    getFillColor: d =>
    d.CQAData && d.CQAData.length > 0 && d.CQAData.some((obj: any) => obj.passOrRetest === null || obj.passOrRetest === "")
    ? [255, 165, 0, 255]
    : d.CQAData && d.CQAData.length > 0 && d.CQAData.find((obj: any) => obj.surveyElevation === d.elevation && obj.passOrRetest === "Pass")
    ? [0, 255, 0, 255]
    : d.CQAData && d.CQAData.length > 0 && d.CQAData.find((obj: any) => obj.surveyElevation === d.elevation && obj.passOrRetest === "Retest")
    ? [0, 0, 255, 255]
    : [0, 0, 0, 150],

    opacity: G100LayerOpacity,
    visible: G100LayerVisible,
    // getColorWeight: d => d["northing"]/10000,
    autoHighlight: true,
    onClick: (info, event) => {
      // console.log('Clicked:', info, event)
      handleGridOnClick(info.object)
    }
  });
  const Excav100GridTextLayer = new TextLayer({
    id: 'excav-100-grid-text-layer',
    data: Excav100Layer,
    pickable: true,
    getPosition: d => 
    [
      d["longitudeDecimal"], 
      d["latitudeDecimal"],
      d["elevation"] 
      && d["elevation"]  !== undefined
      ? d["elevation"] + 5
      : 5
    ],
    getText: d => d["locationID"],
    getColor: d => [255, 255, 255],
    getSize: 10,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center',
    opacity: G100LayerOpacity,
    visible: G100LayerVisible,
    onClick: (info, event) => {
      // console.log('Clicked:', info, event)
      handleGridOnClick(info.object)
    }
  });

  const Backfill200GridLayer = new GridCellLayer({
    id: 'backfill-200-grid-layer',
    data: Backfill200Layer,
    pickable: true,
    extruded: true,
    cellSize: 200*0.3048,
    offset: [0, 0],
    coverage:0.98,
    elevationScale: 1,
    getPosition: d => [d["longitudeDecimal"], d["latitudeDecimal"]],
    getElevation: d => 
    d["elevation"] 
    && d["elevation"]  !== undefined
    ? d["elevation"]
    : 0.1,
    getFillColor: d =>
    d.CQAData && d.CQAData.length > 0 && d.CQAData.some((obj: any) => obj.passOrRetest === null || obj.passOrRetest === "")
    ? [255, 165, 0, 255]
    : d.CQAData && d.CQAData.length > 0 && d.CQAData.find((obj: any) => obj.surveyElevation === d.elevation && obj.passOrRetest === "Pass")
    ? [0, 255, 0, 255]
    : d.CQAData && d.CQAData.length > 0 && d.CQAData.find((obj: any) => obj.surveyElevation === d.elevation && obj.passOrRetest === "Retest")
    ? [0, 0, 255, 255]
    : [0, 0, 0, 150],
    opacity: G200LayerOpacity,
    visible: G200LayerVisible,
    // getColorWeight: d => d["northing"]/10000,
    autoHighlight: true,
    onClick: (info, event) => {
      // console.log('Clicked:', info, event)
      handleGridOnClick(info.object)
    }

  });
  const Backfill200GridTextLayer = new TextLayer({
    id: 'backfill-200-grid-text-layer',
    data: Backfill200Layer,
    pickable: true,
    getPosition: d => 
    [
      d["longitudeDecimal"], 
      d["latitudeDecimal"],
      d["elevation"] 
      && d["elevation"]  !== undefined
      ? d["elevation"] + 5
      : 5
    ],
    getText: d => d["locationID"],
    getColor: d => [255, 255, 255],
    getSize: 18,
    getAngle: 0,
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center',
    opacity: G200LayerOpacity,
    visible: G200LayerVisible,
    onClick: (info, event) => {
      // console.log('Clicked:', info, event)
      handleGridOnClick(info.object)
    }

  });

// onClick event to show Modal for input:

  const [showGridInputModal, setShowGridInputModal] = useState(false);
  // const [showGridInputModal, setShowGridInputModal] = useMyStateHook(false);

  const handleGridInputModalClose= () => {
    setShowGridInputModal(false)
    getAllGridDataFromURL();

  };

  // console.log(PassFailValue100Layer)

  const handleGridInputModalShow = () => setShowGridInputModal(true);
  
  const theme = useTheme();
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down('xl'));

  const deckLayers = [
    imagery_layer,
    PreloadCellPathLayer,
    PreloadCellTextLayer,
    Excav100GridLayer,
    // Excav100ConstructionEffortGridLayer,
    Backfill200GridLayer,
    Excav100GridTextLayer,
    Backfill200GridTextLayer,
    // Backfill200ConstructionEffortGridLayer,
    ]

  return (
    <>
      {isLoading && (
              <MapLoadingComponent/>
            )}

      <Map
      id="main-map-view"
      {...viewState}
      onMove={
        evt => {
          setViewState(evt.viewState);
        }}
      style={{
        width: "100%", 
        flexGrow: 1,

        // height: "90vh",
      }}
      // mapStyle= { map_style }
      mapStyle = "mapbox://styles/mapbox/satellite-v9"
      mapboxAccessToken={MAPBOX_TOKEN}
      maxPitch={85}
      terrain={{source: 'mapbox-dem', exaggeration: 1.5}}
    >
      <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.mapbox-terrain-dem-v1"
        tileSize={512}
        maxzoom={14}
      />
      <Layer {...skyLayer} />
      <DeckGLOverlay layers={deckLayers}
        getTooltip={({object}) => object && object["locationID"] && `locationID: ${object["locationID"]}`}
        />
      <GeolocateControl position="top-left" style={{zIndex: 999}}/>
      {/* <FullscreenControl position="top-left" /> */}
      <NavigationControl position="top-left" style={{zIndex: 999}}/>

      <ScaleControl position="bottom-left" unit='imperial' style={{zIndex: 999}}/> 
      {/* 'imperial' | 'metric' | 'nautical' */}

      {/* <Marker longitude={viewState.longitude} latitude={viewState.latitude} color="red" /> */}
      {/* <MapStyleSelect mode={mode} onModeChange={setMode} /> */}

      </Map>

      <div 
      style={{
        position: "absolute",
        top: "6rem",
        right: "0rem",
        margin: "1rem",
        padding:"1rem",
        backgroundColor: "white",
        opacity:0.8,
        zIndex: 1000,
      }}
      > 
        <LocationIDAutocompleteSelect
        SearchGridIDList={SearchGridIDList}
        />  
      </div>

      <div 
      style={{
        position: "absolute",
        bottom: "1rem",
        right: "0rem",
        // margin: "1rem",
        // padding:"1rem",
        // backgroundColor: "white",
        opacity:0.8,
        zIndex: 999,
      }}
      > 
        <MapColorLegend/>    
      </div>

      <div 
      style={{
        position: "absolute",
        bottom: "3.5rem",
        right: "0rem",
        // margin: "1rem",
        // padding:"1rem",
        backgroundColor: "white",
        opacity:0.8,
        zIndex: 999,
      }}
      > 
        <MapComponentToggleGroup/>    
      </div>

      <BootstrapDialog
        onClose={handleGridInputModalClose}
        aria-labelledby="grid-CQA-input-table-dialog"
        open={showGridInputModal}
        fullScreen={fullScreenDialog}
        maxWidth='xl'
      >
        <BootstrapDialogTitle id="grid-CQA-input-table-dialog" onClose={handleGridInputModalClose}>
          {"Grid ID: " + currentGridID.split("-")[0] + " (" + currentGridType.replace("Grid", "") + " x" + currentGridType.replace("Grid", "") + " )"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {
            currentGridType === "Grid 200"
            ? 
            <FillGridCQAInputTable
              showDialog = {showGridInputModal}
              currentGridID = {currentGridID}
              currentGridData = {currentGridData}
              getCurrentGridData = {getCurrentGridData}
            />
            :
            currentGridType === "Grid 100"
            ?
            <ExcaGridCQAInputTable
              showDialog = {showGridInputModal}
              currentGridID = {currentGridID}
              currentGridData = {currentGridData}
              getCurrentGridData = {getCurrentGridData}
            />
            :            
            null
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleGridInputModalClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>

    </>

  );
}
