import axios from 'axios';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import {
    MaterialReactTable,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';


import dayjs from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {CQADataType} from './data-definition'

// import TestTypeToggleButtonGroup from './test-type-toggle-button-group'
// import MaterialTypeToggleButtonGroup from './material-type-toggle-button-group'

// import TestIDAutocompleteSelect from './test-ID-autocomplete-select'
// import LiftNoAutocompleteSelect from './lift-No-autocomplete-select'
import PassOrRetestToggleButtonGroup from './pass-or-retest-toggle-button-group'
// import ProctorTestIDAutocompleteSelect from './proctor-test-id-autocomplete-select';
// import PreviousSurveyElevationAutocompleteSelect from './previous-survey-elevation-autocomplete-select'

interface CreateModalProps {
    columns: MRT_ColumnDef<CQADataType>[];
    onClose: () => void;
    onSubmit: (values: CQADataType) => void;
    open: boolean;
    tableData: CQADataType[];
    currentGridID: any;
    title: any;
    confirmButtonText: any;
    currentRowIndex: any;
    values: any;
    setValues: any;
    bottomOfCCR: any;
    currentElevation: any;
    setCurrentElevation: any;
   }
  
export function getValueById(id: string) {
  const element = document.getElementById(id) as HTMLInputElement;
  return element ? element.value : null;
}


export function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-create-update-record-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

  //example of creating a mui dialog modal for creating new rows
  export const CreateUpdateRecordModal = ({
    open,
    columns,
    onClose,
    onSubmit,
    tableData,
    currentGridID,
    title,
    confirmButtonText,
    currentRowIndex,
    values,
    setValues,
    bottomOfCCR,
    currentElevation,
    setCurrentElevation,

  }: CreateModalProps) => {
    

    const shortGridID = currentGridID.split("-")[0]

    const noVisibleColumns = ["_id", ]
    // console.log(values)
    const requiredKeys = ['date', ]; 

    // List of keys for which TextField type should be "number"
const numberKeys = ['surveyElevation', 'thicknessBelowCCR', 'requiredThicknessBelowCCR'];

    useEffect(() => {
    if (confirmButtonText === "Update" && tableData && tableData.length > 0 && open === true) {
    // console.log(values)
    // console.log(tableData)
    // console.log(currentRowIndex)

      setValues(tableData[currentRowIndex])
    } else {
      setValues(

        columns.reduce((acc, column) => {
          acc[column.accessorKey ?? ''] = '';
          if (column.accessorKey === 'date') {
            acc[column.accessorKey ?? ''] = dayjs(new Date()).format('MM/DD/YYYY');
          } else if (column.accessorKey === 'requiredThicknessBelowCCR') {
            acc[column.accessorKey ?? ''] = process.env.REACT_APP_REQUIRED_THICKNESS_BELOW_CCR
            ;
          }
          return acc;
        }, {} as any)
        
      )

    }

    }, [confirmButtonText, tableData, open, currentRowIndex]);

    // console.log(values)
  
    // const [dateValue, setDateValue] = React.useState(dayjs(new Date()));
    const [dateValue, setDateValue] = useState(values.hasOwnProperty('date') && values['date'] !== '' ? dayjs(values['date']) : dayjs(new Date()));
    // console.log(dateValue)
    // console.log(values['date'])

    // When the value in the parent component changes, update the local state
    // useEffect(() => {
    //   if (values.hasOwnProperty('date') && values['date'] !== '') {
    //     setDateValue(values['date']);
    //   }
    // }, [values['date']]);

    useEffect(() => {
      if (dateValue) {
        setDateValue(dateValue);
      }
    }, [dateValue]);

    const [errors, setErrors] = useState({});
    const [errorAlert, setErrorAlert] = useState("");
    useEffect(() => {
      // Check if there is at least one error that is not an empty string or undefined
      const hasError = errors && Object.values(errors).some(error => error !== "" && error !== undefined);
    
      // Set the error alert message based on the presence of errors
      setErrorAlert(hasError ? "Please double check your inputs" : "");
    }, [
      errors,
    ]);

    // console.log(errors)


    // calculate thickness below CCR:
    useEffect(() => {
      if (bottomOfCCR !== "" && values.surveyElevation !== "" && bottomOfCCR !== undefined  && values.surveyElevation !== undefined) {
        console.log(values.surveyElevation)
        const surveyElevation = Number(values.surveyElevation);
        const thicknessBelowCCR = (Number(bottomOfCCR) - surveyElevation)*12;
        const roundedThicknessBelowCCR = thicknessBelowCCR.toFixed(1);
    
        setValues(prevValues => ({
          ...prevValues,
          'thicknessBelowCCR': Number(roundedThicknessBelowCCR),
        }));
      } 
      else {
        setValues(prevValues => ({
          ...prevValues,
          'thicknessBelowCCR': "",
        }));
      }
    }, [values.surveyElevation]);

  // console.log(values)


  //pass or retest:
  useEffect(() => {

    if (
      values.hasOwnProperty('requiredThicknessBelowCCR') 
      && values["requiredThicknessBelowCCR"] !== "" 
      && values.hasOwnProperty('thicknessBelowCCR') 
      && values["thicknessBelowCCR"] !== "" 
      ) {
      // thicknessBelowCCR requirement:
      const thicknessBelowCCR = Number(values.thicknessBelowCCR);
      const requiredThicknessBelowCCR = Number(values.requiredThicknessBelowCCR);
      if (thicknessBelowCCR >= requiredThicknessBelowCCR ) {
        const passOrRetest = "Pass"
        setValues({
          ...values,
          'passOrRetest': passOrRetest,
        });
  } else if (( thicknessBelowCCR < requiredThicknessBelowCCR)) {
        const passOrRetest = "Retest"
        setValues({
          ...values,
          'passOrRetest': passOrRetest,
        });
      } else {
        const passOrRetest = ""
        setValues({
          ...values,
          'passOrRetest': passOrRetest,
        });
      }


    }
  }, [
    values.thicknessBelowCCR, 
    values.requiredThicknessBelowCCR,
  ]);
  
    const handleSubmit = async () => {

      // add default values:

      if (!values.hasOwnProperty('date')) {
        values["date"] = dateValue.format('MM/DD/YYYY')
      }
      if (!values.hasOwnProperty('requiredPercentageCompaction')) {
        values["requiredPercentageCompaction"] = getValueById("requiredPercentageCompaction");
      }
  
      //put your validation logic here
      let newErrors = {};
      
      columns.forEach((column) => {
        if (!noVisibleColumns.includes(column.accessorKey)) {
          if (!values[column.accessorKey] && requiredKeys.includes(column.accessorKey)) {
            newErrors[column.accessorKey] = 'Required';
          }          
        }

      });
 
      // Check if 'testID' already exists in 'tableData'
      // if (Array.isArray(tableData) && tableData.some((row: any) => row.testID.toLowerCase() === values.testID.toLowerCase()) && confirmButtonText === "Create") {
      //   newErrors["testID"] = 'This test ID already exists';
      // }
  

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        // console.log(values)
        // console.log(newErrors)
        return;
      }

      // console.log('Submitting form', values);

      // Continue with form submission
      // console.log(values)
      onSubmit(values);
      setValues({})
      onClose();
    };
  
    return (
      <Dialog 
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-create-update-record-dialog"      
      >
        <DialogTitle textAlign="center"> {title} </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            justifyContent="center"
            // alignItems="center" 
            className="my-1 py-1"
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) => {

                if (!noVisibleColumns.includes(column.accessorKey)) {            

                // List of keys for which TextField should be disabled
                const disabledKeys = ['wetDensity', 'optimumMoistureContent', 'maxDryDensity', 'percentageCompaction', 'liftThickness',];

                
                // If column.accessorKey is in requiredKeys, TextField will be required
                const isRequired = requiredKeys.includes(column.accessorKey);
                // If column.accessorKey is in numberKeys, TextField type will be "number". Otherwise, it will be "text".
                const inputType = numberKeys.includes(column.accessorKey) ? 'number' : 'text';
                // If column.accessorKey is in disabledKeys, TextField will be disabled
                const isDisabled = disabledKeys.includes(column.accessorKey);

                return (
                  column.accessorKey === 'date'
                  ? 
                  <FormControl
                    // required
                    fullWidth
                    key = {column.accessorKey + "-form-control"}
                  >
                    {/* <InputLabel>{column.header}</InputLabel> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label={column.header + "* (mm/dd/yyyy)"}
                        key={column.accessorKey}
                        maxDate={dayjs(new Date())}
                        // name={column.accessorKey}
                        value={dateValue}
                        onChange={(newValue) => {
                          if (newValue) {
                            setValues({ ...values, ["date"]: newValue.format('MM/DD/YYYY') });
                          }
                          setDateValue(newValue)
                          }
                        }

                        // renderInput={(params) => <TextField {...params} />}
                        
                      />
                    </LocalizationProvider>
                  </FormControl>
                  :
                  // column.accessorKey === 'materialType'
                  // ?
                  // <FormControl 
                  // fullWidth 
                  // required
                  // key = {column.accessorKey + "-form-control"}
                  // >
                  // <MaterialTypeToggleButtonGroup
                  //   values = {values}
                  //   setValues={setValues}
                  //   confirmButtonText = {confirmButtonText}
                  //   errors = {errors}
                  //   setErrors = {setErrors}               
                  // />
                  // </FormControl>
                  // :
                  // column.accessorKey === 'testType'
                  // ?
                  // <FormControl 
                  // fullWidth 
                  // required
                  // key = {column.accessorKey + "-form-control"}
                  // >
                  // <TestTypeToggleButtonGroup
                  //   values = {values}
                  //   setValues={setValues}
                  //   confirmButtonText = {confirmButtonText}
                  //   errors = {errors}
                  //   setErrors = {setErrors}                    
                  // />
                  // </FormControl>
                  // :  
                  // column.accessorKey === 'testID'
                  // ?
                  // <FormControl
                  //   required
                  //   fullWidth
                  //   key = {column.accessorKey + "-form-control"}
                  // > 
                  //   <TestIDAutocompleteSelect
                  //   values = {values}
                  //   setValues={setValues}
                  //   shortGridID ={shortGridID}
                  //   confirmButtonText = {confirmButtonText}
                  //   errors={errors}
                  //   setErrors = {setErrors}   
                  //   />
                  // </FormControl>
                  // :
                  // column.accessorKey === 'liftNo'
                  // ?
                  // <FormControl
                  //   required
                  //   fullWidth
                  //   key = {column.accessorKey + "-form-control"}
                  // > 
                  //   <LiftNoAutocompleteSelect
                  //     values = {values}
                  //     setValues={setValues}
                  //     shortGridID ={shortGridID}
                  //     errors = {errors}
                  //     setErrors = {setErrors}   
                  //     />
                  // </FormControl>
                  // :
                  // column.accessorKey === 'previousSurveyElevation'
                  // ?
                  // <FormControl
                  //   // required
                  //   fullWidth
                  //   key = {column.accessorKey + "-form-control"}
                  // > 
                  //   <PreviousSurveyElevationAutocompleteSelect
                  //     values = {values}
                  //     setValues={setValues}
                  //     tableData = {tableData}
                  //     bottomOfCCR = {bottomOfCCR}
                  //     />
                  // </FormControl>
                  // :
                  column.accessorKey === 'passOrRetest'
                  ?
                  <FormControl 
                  fullWidth 
                  // required
                  key = {column.accessorKey + "-form-control"}
                  >
                  <PassOrRetestToggleButtonGroup
                    values = {values}
                    setValues={setValues}                
                  />
                  </FormControl>
                  :
                  column.accessorKey === 'notes'
                  ?             
                  <TextField
                        style={{color: "white"}}
                        multiline       
                        // required
                        key={column.accessorKey}
                        label={column.header}
                        name={column.accessorKey}
                        type="string"
                        onChange={(e) =>
                            setValues({ ...values, [e.target.name]: e.target.value })
                        }
                        error={Boolean(errors[column.accessorKey])}
                        helperText={errors[column.accessorKey]}
                    />         
                  :         
                  <FormControl
                  required={isRequired}
                  fullWidth
                  key = {column.accessorKey + "-form-control"}
                  >  
                    <TextField
                      id={column.accessorKey}
                      required={isRequired}
                      fullWidth
                      disabled={isDisabled}
                      value={column.accessorKey === "requiredThicknessBelowCCR" 
                      ? 
                      (values[column.accessorKey] || process.env.REACT_APP_REQUIRED_THICKNESS_BELOW_CCR) 
                      :                                              
                      (values[column.accessorKey] || '')}
                      key={column.accessorKey}
                      label={column.header}
                      name={column.accessorKey}
                      type={inputType}
                      onChange={(e) => {
                        // console.log(e.target.value===null)
                        // console.log(e.target.value===undefined)
                        // console.log(e.target.value==="")
                        // console.log(Number(e.target.value))
                        // // convert to float
                        // const value = ['maximumDryDensity', 'optimumMoistureContent', 'wetDensity'].includes(e.target.name)
                        // ? parseFloat(e.target.value)
                        // : e.target.value;
                        // save input data
                        setValues(values => ({
                          ...values, 
                          [e.target.name]: numberKeys.includes(e.target.name) && e.target.value!==""
                          ? Number(e.target.value) 
                          : e.target.value 
                        }));

                        // setValues({ ...values, [e.target.name]: e.target.value });
                        if (errors[e.target.name]) {
                          // If there's an error for this field, clear it when the field is modified
                          setErrors({ ...errors, [e.target.name]: null });
                        }
                      }}
                      error={Boolean(errors[column.accessorKey])}
                      helperText={errors[column.accessorKey]}
                    />               
                  </FormControl>
                  
                  )
                } 
  
                  })}
            </Stack>
          </form>
        </DialogContent>
        <Alert 
          severity="error" 
          sx={{ p: '0rem', m: '1.25rem' }} 
          color='error' 
          style={errors && errors && Object.values(errors).some(error => error !== "" && error !== undefined) ? {display:"flex"} : {display:"none"}}
        >
          <AlertTitle>Error</AlertTitle>
          {errorAlert}
      </Alert>

        <DialogActions sx={{ p: '1.25rem' }}>
          <Button color="inherit" onClick={onClose}>Cancel</Button>
          <Button color={confirmButtonText==="Create"?"success": "warning"} onClick={handleSubmit} variant="contained">
          {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };