import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useMemo, useState, useEffect } from 'react';

interface SetValuesProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  tableData: any;
  initialElevation: any;
}

export default function PreviousSurveyElevationAutocompleteSelect(props: SetValuesProps) {

  const {values, setValues, tableData, initialElevation} = props;


  const [value, setValue] = useState(values['previousSurveyElevation'] === '' ? null : values['previousSurveyElevation'] || null);



  // When the value in the parent component changes, update the local state
  useEffect(() => {
    setValue(values['previousSurveyElevation'] === '' ? null : values['previousSurveyElevation'] || null);
  }, [values['previousSurveyElevation']]);


  return (
    <Autocomplete
      disablePortal
      disableClearable
      value={value} // Use the local state value
      id="previous-survey-elevation-autocomplete-select"
      onChange={(event: any, newValue: any) => {
        setValue(newValue)
        setValues({ ...values, ["previousSurveyElevation"]: newValue})
      }}
      options={
        tableData.length > 0 
        ? Array.from(new Set([initialElevation, ...tableData.map(obj => obj.surveyElevation)].filter(surveyElevation => surveyElevation !== null && surveyElevation !== undefined))).sort((a, b) => b - a)
        : [initialElevation]
      }
      getOptionLabel={(option:any) => `${option}`}
    //   sx={{ 
    //     width: 150,
    //     backgroundColor: 'white'

    // }}
      renderInput={(params) => <TextField 
        {...params} 
        label="Previous Survey Elevation" 
        InputProps={{
            ...params.InputProps,
            type: 'search',
          }}        
        />}
    />
  );
}
