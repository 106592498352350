// import { ScatterplotLayer } from "@deck.gl/layers/typed";
// import { MapboxOverlay, MapboxOverlayProps } from "@deck.gl/mapbox/typed";
// import { useControl } from "react-map-gl";

// import Map, { NavigationControl } from "react-map-gl";

// import MainMapView from "./components/map/main-map-view";
// import MainPage from "./pages/main_page";

// export default function App() {

//   return (
//     <MainPage/>
//   );
// }
import React, { useState, useEffect } from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import Login from './pages/login_page'
import MainPage from './pages/main_page'
import UpdateTrackerProvider from './functions/UpdateTrackerProvider';

import {RequireToken} from './functions/Auth'
import useAutoLogout from './functions/useAutoLogout';


const isLoggedIn = () => {
  // Check if the user has a valid GSToken in localStorage
  // Return true if the token is valid, false otherwise
  return localStorage.getItem('GSToken') !== null;
};

export default function App() {

  useAutoLogout();

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/service-worker.js')
  //       .then((registration) => {
  //         console.log('Service Worker registered:', registration);
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error);
  //       });
  //   }
  // }, []);

  return (
    <div 
    className="App"
    style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%',
      // height: "100vh",
      width: "100%", 
   }}
    >
      <Routes>
        <Route path="/" element={isLoggedIn() ? <MainPage /> : <Login />} />
        <Route path="/login" element={<Login 
        />} />
        <Route
          path="/main"
          element={
            <RequireToken>
                <MainPage/>
            </RequireToken>
          }
        />
      </Routes>
    </div>
  );
}