import Plot from 'react-plotly.js';

export default function MapColorLegend() {

  const legendText = ["Pass", "Retest", "Incomplete"]

    return (
        <Plot 
          data={[
            {
              x: [10, 12, 14, ],
              y: [2, 2, 2, ],
              width: [2, 2, 2, ],
              text: legendText,
              // x: [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30],
              // y: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
              // width: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
              // text: ["<=10%", "<=20%", "<=30%", "<=40%", "<=50%", "<=60%", "<=70%", "<=80%", "<=90%", "<=100%", ">100%"],
              type: 'bar',
              mode: 'text+lines+markers',
              marker: {color: ["#00FF00", "#0000FF","#FFA500"]},
              // marker: {color: ["#ff0000", "#d73027", "#f46d43", "#fdae61", "#fee08b", "#ffffbf", "#d9ef8b", "#a6d96a", "#66bd63", "#1a9850", "#0000FF"]},
            //   hoverinfo: 'text',
              textposition: 'inside',
              textfont: {
                size:30,
            },
            },
          ]}
          layout={ {
            width: 320, 
            height: 30, 
            // title: {
            //     text:'Color Legend',
            // },
            hovermode:'closest',
            dragmode: false,
            autosize: true, 
            margin: {
                l: 0,
                r: 0,
                b: 0,
                t: 0,
                pad: 5,
                },
            }
        }
           config={{
            displayModeBar: false,
            scrollZoom: false,
        }}        
        />
    )

}

