import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./assets/bootstrap.css";


const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container) if you use Javascript
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);