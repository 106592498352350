import * as React from 'react';
import { 
    useState, 
    useCallback,
    useMemo,
    useEffect, 
  } from 'react';
  
import {useNavigate} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
// import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';

import { 
    AppBar,
    Avatar,
    Box, 
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Switch,
    Toolbar,
    Tooltip,
    Typography, 

} from '@mui/material';


import MenuIcon from '@mui/icons-material/Menu';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import WifiChannelIcon from '@mui/icons-material/WifiChannel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import LoadingComponent from '../components/loading-page'

import MainMapView from '../components/map/main-map-view';
import ProctorInputDialog from '../components/proctor_inputs/proctor-input-dialog'
import DownloadOffcanvas from '../components/download_items/download-offcanvas'

const CompanyGraphyLogoLayerURL = require('../assets/small-logo.png')

const pages:any = [
        {
            "key":'Proctor Test', 
            "element": <WifiChannelIcon color="inherit" sx={{ fontSize: 30 }} />,
    
        },
        {
            "key":'Download', 
            "element": <CloudDownloadIcon color="inherit" sx={{ fontSize: 30 }} />,

        },
        // 'Pricing', 
        // 'Blog',
    ];
    const settings = [
        // 'Profile', 
        // 'Account', 
        // 'Dashboard', 
        'Logout'
    ];
    
    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#1976d2',
          },
        },
      });
    
    export interface Metadata {
        category: string;
        filename: string;
        page_number: number;
        source: string;
    
    }  
    export interface Chat {
        message: string;
        isUser: boolean;
        // username: string;
        sourceDocument: Metadata[] | null;
      }
    
    
    interface QuerySourceDocument {
        metadata: Metadata;
        page_content: string;
    }
    
export default function MainPage(){
    const appName = process.env.REACT_APP_NAME

    const navigate = useNavigate();
    const signOut = () => {
        localStorage.removeItem('GSToken')
        navigate("/login");
    }

    const [isLoading, setIsLoading] = useState(false);

    const expand = 'lg'

    const [showDownloadOffcanvas, setDownloadOffcanvas] = React.useState(false);

    const username = localStorage.getItem('Username')
    // console.log("username: ", username)

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        const buttonText = event.currentTarget.textContent;
        // console.log(buttonText)
        if (buttonText==="Logout") {
            signOut();
        }

        setAnchorElUser(null);
    };

//  proctor-input-dialog:
    const [showProctorInputDialog, setShowProctorInputDialog] = useState(false);
    const handleProctorInputDialogShow = () => setShowProctorInputDialog(true);

// handleClickOnNavItemPage:
    const handleClickOnNavItemPage = (event: React.MouseEvent<HTMLElement>) => {
        const navItemKey = event.currentTarget.getAttribute('aria-label');
        // console.log(navItemKey)
        if (navItemKey ==="Proctor Test") {
            handleProctorInputDialogShow()
        }
        if (navItemKey ==="Download") {
            setDownloadOffcanvas(true)
        }
        
        setAnchorElNav(null);
      };


// online/offline status:
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
        window.removeEventListener('online', updateOnlineStatus);
        window.removeEventListener('offline', updateOnlineStatus);
    };
    }, []);

    const handleOfflineToggle = () => {
        setIsOnline(!isOnline);
        // Add any additional logic for switching between online and offline mode here...
      };

    return(
        <div
        style={{
            width: "100%", 
            height: "100vh",
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh',
        }}
        >
            <ThemeProvider theme={darkTheme}>
                <AppBar position="static" color="primary">
                    {/* <Container
                    maxWidth="xl"
                    > */}
                        <Toolbar 
                        // disableGutters
                        >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                        mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            // justifyContent: "center",
                            alignItems: "center",
                            fontFamily: 'monospace', // monospace, Roboto
                            // fontWeight: 700,
                            // letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                            <Box display={{ xs: 'none', md: 'flex' }}>
                                <img
                                    alt="CompanyLogo"
                                    src={CompanyGraphyLogoLayerURL}
                                    width="40"
                                    // height="30"
                            className="d-inline-block align-center mx-2"
                                />
                            </Box>

                            {appName}
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                            size="large"
                            edge="start"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            >
                            <MenuIcon />
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                            >
                            {pages.length > 0 ? (pages.map((page:any) => (
                                <Tooltip title={page.key} key={page.key + "-sx-tooltip"}>
                                    <MenuItem onClick={handleClickOnNavItemPage}>
                                    <Typography textAlign="center">{page.element}</Typography>
                                    </MenuItem>                                    
                                </Tooltip>

                            ))) : (
                                null
                              )
                              }
                            </Menu>
                        </Box>
                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: 'monospace', // monospace, Roboto
                            // fontWeight: 700,
                            // letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                            <Box display={{ xs: 'flex', md: 'none' }}>
                                <img
                                    alt="CompanyLogo"
                                    src={CompanyGraphyLogoLayerURL}
                                    width="40"
                                    // height="30"
                                className="d-inline-block align-center mx-2"
                                />
                            </Box>

                            {appName}
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.length > 0 ? (pages.map((page:any) => (
                                <Tooltip title={page.key} key={page.key + "-md-tooltip"}>
                                    <Button
                                        key={page.key}
                                        onClick={handleClickOnNavItemPage}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        {page.element}
                                    </Button>                                
                                </Tooltip>

                            ))) : (
                                null
                              )
                            }
                        </Box>
                        <Box sx={{ flexGrow: 0, p:2, display: 'flex', alignItems: 'center' }}>
                            {isOnline ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
                            <Typography variant="body2" sx={{ ml: 1 }}>{isOnline ? 'Online' : 'Offline'}</Typography>
                            {/* <Switch checked={isOnline} onChange={handleOfflineToggle} /> */}
                        </Box>


                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="" src=""> {username ?username.charAt(0).toUpperCase(): null} </Avatar>
                            </IconButton>
                            </Tooltip>
                            <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                            </Menu>
                        </Box>
                        </Toolbar>
                    {/* </Container> */}
                </AppBar>
                <ProctorInputDialog 
                    showDialog = {showProctorInputDialog}
                    setShowDialog = {setShowProctorInputDialog}
                />  
                <DownloadOffcanvas 
                    values = {showDownloadOffcanvas}
                    setValues={setDownloadOffcanvas}              
                />              
            </ThemeProvider>

            {isLoading && (
        <LoadingComponent/>
      )}

            <MainMapView/>

        </div>
    )
}