import React, { 
  useCallback, 
  useMemo, 
  useState,
  useEffect,
 } from 'react';

import axios from 'axios';

import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import type {
  MRT_ColumnDef,
  MRT_Cell,
  MRT_Row,
  MRT_TableOptions,
} from 'material-react-table';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  FormControl,
  Alert,
  AlertTitle,
  Grid,

} from '@mui/material';
import { Delete, Edit, ThumbUpAlt, ThumbDownAlt, QuestionMark } from '@mui/icons-material';

// import { Modal, Button } from 'react-bootstrap';


import {hasUniqueCombination} from '../../functions/HasUniqueCombinations'

import {CQADataType} from './data-definition'
import {ProctorCompactionTest} from '../proctor_inputs/data-definition'

import {CreateUpdateRecordModal} from './create-update-record-modal'

import MapLoadingComponent from '../map/map-loading-component'


export const CQADataTypeData: CQADataType[] = [
];
export const ProctorCompactionTestData: ProctorCompactionTest[] = [
];


interface SetValuesProps {
  showDialog: any;
  currentGridID: any;
  currentGridData: any;
  getCurrentGridData: any;
}



const ExcaGridCQAInputTable = (props: SetValuesProps) => {

    const {showDialog, currentGridID, currentGridData, getCurrentGridData} = props;
    const [isLoading, setIsLoading] = useState(false);

    const [bottomOfCCR, setBottomOfCCR] = useState(
      currentGridData.hasOwnProperty("bottomOfCCR") && currentGridData["bottomOfCCR"] !== "" 
        ? currentGridData["bottomOfCCR"] 
        : 0
    );
    // const [currentElevation, setCurrentElevation] = useState(0);

    const [currentElevation, setCurrentElevation] = useState(
      currentGridData.hasOwnProperty("elevation") && currentGridData["elevation"] !== "" 
        ? currentGridData["elevation"] 
        : 0
    );

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [tableData, setTableData] = useState<CQADataType[]>(() => CQADataTypeData);

//  Alert:
const [showAlert, setShowAlert] = useState(true);

type AlertType = "error" | "info" | "success" | "warning" | undefined;
const [inputAlertType, SetInputAlertType] = useState("success" as AlertType);
const [inputAlertText, SetInputAlertText] = useState("Changes saved!");    
const [updateDBSuccess, setUpdateDBSuccess] = useState(true);

//  Update currentElevation when tableData changes:

function calcCurrentSurveyElevation(tableData: any[]) {
  if (tableData.length > 0) {
    const sortedTableData = [...tableData].sort((a, b) => {
      const dateA = new Date(a.date) as Date;
      const dateB = new Date(b.date) as Date;
    
      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        return 0;
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
    
    const latestDate = sortedTableData[0].date;
    const latestDateObjects = sortedTableData.filter(obj => 'surveyElevation' in obj && obj.surveyElevation.toString() !== "");

    if (latestDateObjects.length > 0) {
      // const isDecreasing = latestDateObjects[0].surveyElevation < latestDateObjects[0].previousSurveyElevation;
      let surveyElevation;

      surveyElevation = Math.min(...latestDateObjects.map(obj => parseFloat(obj.surveyElevation.toString())).filter(num => !isNaN(num)));
      
      // if (isDecreasing) {
      //   surveyElevation = Math.min(...latestDateObjects.map(obj => parseFloat(obj.surveyElevation.toString())).filter(num => !isNaN(num)));
      // } else {
      //   surveyElevation = Math.max(...latestDateObjects.map(obj => parseFloat(obj.surveyElevation.toString())).filter(num => !isNaN(num)));
      //   surveyElevation = null
      // }

      return surveyElevation;
    }
  }

  return null;
}

useEffect(() => {
  console.log(tableData)
  const currentSurveyElevation = calcCurrentSurveyElevation(tableData);
  console.log("currentSurveyElevation: ", currentSurveyElevation)
  if (currentSurveyElevation !== null) {
    setCurrentElevation(currentSurveyElevation);
  };

}, [tableData]);

//  Load CQA data from DB:

  useEffect(() => {
    if (showDialog===true) {

      // console.log(currentGridData)

      if (currentGridData.CQAData) {
        // console.log(currentGridData.CQAData)
        setTableData(currentGridData.CQAData)
      }
    }
    }, [showDialog]);

  // Data Validation:
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  // const validateRequired = (value: string) => !!value.length;
  const validateRequired = (value: string) => null;

// update grid data from DB function:
  async function updateDBGridData() {

    const mainDBUrl = process.env.REACT_APP_CQA_POINTS_URL + currentGridID

    const updatedValues = currentGridData

    var filteredTableData = tableData.map(obj => {
      // Creating a new object to avoid mutating the original data
      var newObj = {};
      for (var key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] !== "" && obj[key] !== null && obj[key] !== "NaN") {
          newObj[key] = obj[key]; // Copy over non-empty values
        }
      }
      return newObj; // Return the new object with empty string values removed
    });
    
    // console.log(filteredTableData)

    updatedValues.CQAData = filteredTableData
    updatedValues.bottomOfCCR = Number(bottomOfCCR)
    const currentSurveyElevation = calcCurrentSurveyElevation(tableData);
    console.log("currentSurveyElevation: ", currentSurveyElevation)
    if (currentSurveyElevation !== null) {
    updatedValues.elevation = Number(currentSurveyElevation)}
    else {
      updatedValues.elevation = Number(currentElevation)
    }
    getCurrentGridData(updatedValues)
    delete updatedValues['_id'];

    // console.log(process.env.REACT_APP_BACKEND_URL + mainDBUrl)
    console.log(updatedValues)

    if (updatedValues) {
      setIsLoading(true)
      axios.put(process.env.REACT_APP_BACKEND_URL + mainDBUrl, updatedValues).then(function (response) {
        // console.log(updatedValues);
    
        // console.log(response.data);
        // console.log("New data has been added!")

        setUpdateDBSuccess(true)
        console.log(`CQA result performed on "${selectedRow && selectedRow.getValue('date')}" has been added/updated/deleted for Grid "${currentGridID}"!`)
      })
      .catch(function (error) {
        // handle error
        console.error(error);
        setUpdateDBSuccess(false)
        SetInputAlertType("error")
        SetInputAlertText(error)
      })
      .then(function () {
        // always executed
      })
      .finally(() => {
        setIsLoading(false);
      });
      };
  }


// Create new row:
  const createNewModalTitle = "Create New Record"
  const createNewModalConfirmButtonText = "Create"

  const handleCreateNewRow = async (values: CQADataType) => {
  // send new data row to table:
  // console.log(validationErrors)
  // console.log(tableData)
  // console.log(values)
  setCurrentRowValues(values)
  tableData.push(values);
  setTableData([...tableData]);
  setIsLoading(true);
  updateDBGridData()
  if (updateDBSuccess === true) {
    SetInputAlertType("success")
    SetInputAlertText(`A new CQA result performed on "${selectedRow && selectedRow.getValue('date')}" has been added!`)
  }

};

// Update existing row:
const updateRowModalTitle = "Update Existing Record"
const updateRowModalConfirmButtonText = "Update"
const [updateModalOpen, setUpdateModalOpen] = useState(false);
const [selectedRow, setSelectedRow] = useState(null);


const handleUpdateRowView = async (row: MRT_Row<CQADataType>) => {
  setSelectedRow(row);
  if (tableData && tableData.length>0 ) {
    setCurrentRowValues(tableData[row.index]);
} 
  // console.log(tableData)
  // console.log(row.index)
  setUpdateModalOpen(true);
}

const handleConfirmUpdateRow = async (values: CQADataType) => {
  // send new data row to table:
  if (validationErrors) {
    // update data table in front view:
    const index = selectedRow.index;
    tableData[index] = values;
    // console.log(tableData)
    // console.log(values)
    setTableData([...tableData]);
    updateDBGridData()
    if (updateDBSuccess === true) {
      SetInputAlertType("info")
      SetInputAlertText(`CQA result performed on "${selectedRow && selectedRow.getValue('date')}" has been updated!`)
    }
    }
  };

// Cancel row edits:
  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  //  Delete data:
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // const [selectedRow, setSelectedRow] = useState(null);

  const handleDeleteRow = useCallback(
    (row: MRT_Row<CQADataType>) => {
      setDeleteDialogOpen(true);
      setSelectedRow(row);
    },
    [],
  );

  const handleConfirmDelete = useCallback(
    () => {
      setDeleteDialogOpen(false);
      //send api delete request here, then refetch or update local table data for re-render
      const index = selectedRow.index;
// Delete document from DB:

      // delete row in data table in front view:
      if (index > -1) {
        tableData.splice(index, 1);
        setTableData([...tableData]);
      }
//  Delete data from DB:
      updateDBGridData()
      if (updateDBSuccess === true) {
        SetInputAlertType("warning")
        SetInputAlertText(`A CQA result performed on "${selectedRow && selectedRow.getValue('date')}" has been deleted!`)
      }

      },
      [tableData, selectedRow],      
      );

  const handleCloseDeleteDialog = useCallback(
    () => {
      setDeleteDialogOpen(false);
    },
    [],
  );

  const columns = useMemo<MRT_ColumnDef<CQADataType>[]>(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        // size: 80,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
        muiTableBodyCellCopyButtonProps: {
          fullWidth: true,
          // startIcon: <ContentCopy />,
          sx: { justifyContent: 'flex-start' },
        },
      },
      {
        accessorKey: 'surveyElevation',
        header: 'Survey Elevation (ft.)',
        // size: 120,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
      },
      {
        accessorKey: 'thicknessBelowCCR',
        header: 'Thickness Below CCR (in.)',
        // size: 120,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
      },    
      {
        accessorKey: 'requiredThicknessBelowCCR',
        header: 'Required Thickness Below CCR (in.)',
        // size: 120,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
      },   
      {
        accessorKey: 'passOrRetest',
        header: 'Pass or Retest',
        // size: 80,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
      },   
      // {
      //   accessorKey: 'uscsClassification',
      //   header: 'USCS',
      //   // size: 120,
      //   enableColumnOrdering: true,
      //   enableClickToCopy: true,
      //   enableEditing: true, 
      //   enableSorting: true,
      // }, 
      // {
      //   accessorKey: 'soilColor',
      //   header: 'Munsell Color',
      //   // size: 120,
      //   enableColumnOrdering: true,
      //   enableClickToCopy: true,
      //   enableEditing: true, 
      //   enableSorting: true,
      // },
      // {
      //   accessorKey: 'pictureID',
      //   header: 'Picture ID',
      //   // size: 120,
      //   enableColumnOrdering: true,
      //   enableClickToCopy: true,
      //   enableEditing: true, 
      //   enableSorting: true,
      // },
      // {
      //   accessorKey: 'pictureURL',
      //   header: 'Picture URL',
      //   // size: 80,
      //   enableColumnOrdering: true,
      //   enableClickToCopy: true,
      //   enableEditing: true, 
      //   enableSorting: true,
      // },
      // {
      //   accessorKey: 'recordedBy',
      //   header: 'Recorded By',
      //   // size: 80,
      //   enableColumnOrdering: true,
      //   enableClickToCopy: true,
      //   enableEditing: true, 
      //   enableSorting: true,
      // },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 80,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
      },
    ],
    [],
  );

    const [currentRowValues, setCurrentRowValues] = useState<any>({})

    const dataTable = useMaterialReactTable({
      displayColumnDefOptions: {
        'mrt-row-actions': {
          muiTableHeadCellProps: {
            align: 'center',
          },
          size: 120,
        },
      },
      columns: columns,
      data: tableData,
      createDisplayMode: 'modal',
      editDisplayMode: "modal", //default
      enableEditing: true,
      enableColumnOrdering:true,
      enableRowActions: true,
      enableColumnPinning: true,
      // enableEditing:true,
      // onEditingRowSave: handleSaveRowEdits,
      onEditingRowCancel: handleCancelRowEdits,
      initialState: {
        columnVisibility: { _id: false },
        columnPinning: { left: ['mrt-row-actions', 'thicknessBelowCCR'], right: [] },
      
      }, 
      muiTableBodyRowProps: ({
        row
      }) => ({
        // sx: {
        //   backgroundColor: row.getValue<string>('passOrRetest') === "Pass" ? 'rgba(0, 255, 0, 0.3)' : row.getValue<string>('passOrRetest') === "Retest" ? 'rgba(0, 0, 255, 0.3)' : 'rgba(255, 128, 0, 0.3)',
        //   fontStyle: 'italic'
        // }
      }),

      renderRowActions: ({row}) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>

          <Tooltip arrow placement="left" title="Edit">
            <IconButton onClick={() => {
              handleUpdateRowView(row)
            }}>
              {/* <Edit /> */}
              {
                row.getValue<string>('passOrRetest') === "Pass" ? <ThumbUpAlt color="success" /> : row.getValue<string>('passOrRetest') === "Retest" ? <ThumbDownAlt color="info" /> : <QuestionMark color="warning"/>

              }
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="right" title="Delete">
            <IconButton color="error" onClick={() => {
              // console.log(tableData[row.index])
              handleDeleteRow(row)
              }}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      renderTopToolbarCustomActions: () => (
        <div style={{width: "100%",}}>
          {showAlert && 
            <Alert 
          severity={inputAlertType}
          sx={{ 
            p: '0rem', 
            m: '1.25rem', 
            backgroundColor: 'transparent',
        }} 
          // color={inputAlertType}
          style={ inputAlertText!=='' ? {display:"flex"} : {display:"none"}}
          >
              <AlertTitle>{inputAlertText}</AlertTitle>
              {/* {error} */}
            </Alert>       
            }
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {/* initial elevation input: */}
            <TextField
              style={{color: "white", margin: '1rem'}}
              // required
              key="bottomOfCCR"
              label="Bottom of CCR (ft.)"
              name="bottomOfCCR"
              type="number"
              value= {bottomOfCCR}
              onChange={(e) => {
                setBottomOfCCR(e.target.value);
                // console.log(e.target.value);
              }}
              onBlur={updateDBGridData}
          />
            <TextField
                style={{color: "white", marginRight: '1rem'}}       
                // required
                key="currentElevation"
                label="Current Elevation (ft.)"
                name="currentElevation"
                type="number"
                value= {currentElevation}
                onChange={(e) => {
                  setCurrentElevation(e.target.value );
                  // console.log(e.target.value);
                }

                }
                onBlur={updateDBGridData}
              />
            <Button
              style={{marginRight: '1em'}}
              color="success"
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              Create New Record
            </Button>
          </div>

        </div>
      ),

    })

    return (
      <>
      {isLoading && (
              <MapLoadingComponent/>
            )}

      <MaterialReactTable 
      table = {dataTable}
      />

      {/* create new row modal */}
      <CreateUpdateRecordModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        tableData= {tableData}
        currentGridID = {currentGridID}
        title = {createNewModalTitle}
        confirmButtonText = {createNewModalConfirmButtonText}
        currentRowIndex={selectedRow ? selectedRow.index : 0}
        values = {currentRowValues}
        setValues = {setCurrentRowValues}
        bottomOfCCR = {bottomOfCCR}
        currentElevation = {currentElevation}
        setCurrentElevation = {setCurrentElevation}
      />
      {/* update existing row modal */}
      <CreateUpdateRecordModal
        columns={columns}
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        onSubmit={handleConfirmUpdateRow}
        tableData= {tableData}
        currentGridID = {currentGridID}
        title = {updateRowModalTitle}
        confirmButtonText = {updateRowModalConfirmButtonText}
        currentRowIndex={selectedRow ? selectedRow.index : 0}
        values = {currentRowValues}
        setValues = {setCurrentRowValues}
        bottomOfCCR = {bottomOfCCR}
        currentElevation = {currentElevation}
        setCurrentElevation = {setCurrentElevation}
      />


      {/* delete row dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete excavation survey performed on "${selectedRow && selectedRow.getValue('date')}"?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="warning" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>



      {/* {isLoading && (
              <LoadingComponent/>
            )} */}
    </>
    );
  };
  
  export default ExcaGridCQAInputTable;