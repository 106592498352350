import React, { useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DatasetIcon from '@mui/icons-material/Dataset';
import Divider from '@mui/material/Divider';

interface SetValuesProps {
    values: any;
    setValues: React.Dispatch<React.SetStateAction<any>>;
  }

type FlattenedObject = { [key: string]: string | number };

function flattenObject(ob: { [key: string]: any }): FlattenedObject {
  let result: FlattenedObject = {};

  for (let i in ob) {
      if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      let temp = flattenObject(ob[i]);
      for (let j in temp) {
          result[i + '.' + j] = temp[j];
      }
      } else {
      result[i] = ob[i];
      }
  }
  return result;
}

  export default function DownloadOffcanvas(props: SetValuesProps) {

    const {values, setValues} = props;

    const anchor = 'left'

    const downloadItem: any = [
        {
            "key":'Grid Data', 
            "element": <DatasetIcon color="inherit" sx={{ fontSize: 30 }} />,
    
    },

    ];

    // const handleDownload = (data: any) => {
    //     const csv = Papa.unparse(data);
    //     const csvData = new Blob([csv], { type: 'text/csv' });  
    //     const csvUrl = URL.createObjectURL(csvData);
    
    //     const link = document.createElement('a');
    //     link.href = csvUrl;
    //     link.download = 'Grid Data.csv';
    //     link.click();
    //   }

    const handleDownload = (data: any[]) => {
      // Collect all unique keys from the data array
      const allKeys = new Set<string>();
      data.forEach(item => {
          Object.keys(item).forEach(key => allKeys.add(key));
      });
  
      // Create a new array of objects with all keys included
      const completeData = data.map(item => {
          const newItem: { [key: string]: any } = {};
          allKeys.forEach(key => newItem[key] = item[key] || '');
          return newItem;
      });
  
      // Use PapaParse to convert the data with all keys to CSV
      const csv = Papa.unparse(completeData, {
          header: true,
          skipEmptyLines: true
      });
      const csvData = new Blob([csv], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvData);
  
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'CQA Data.csv';
      link.click();
  };

    const handleClickItem = (event: React.MouseEvent<HTMLElement>) => {
        const itemKey = event.currentTarget.getAttribute('id');
        console.log(itemKey)
        if (itemKey ==="Grid Data Button") {
            const point_url = process.env.REACT_APP_CQA_POINTS_URL
            axios.get(process.env.REACT_APP_BACKEND_URL + point_url).then(function (response) {
            // handle success
            console.log("Successfully loaded data!");
            // const gridData = response.data.filter((item:any) => item["locationType"] === "Grid 100")
            const gridData = response.data

            console.log(gridData)

            let flattenedGridData = [];

            gridData.forEach(item => {
              if (item.CQAData) {
                  item.CQAData.forEach((cqaItem, index) => {
                      let flattenedItem = {...item, ...cqaItem};
                      delete flattenedItem.CQAData;
                      // flattenedItem = {...flattenedItem, [`CQAData_${index}`]: cqaItem};
                      flattenedGridData.push(flattenedItem);
                  });
              } else {
                  flattenedGridData.push(item);
              }
          });
          
            // let downloadData = gridData.map(flattenObject);
            console.log(flattenedGridData)
            handleDownload(flattenedGridData)
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .then(function () {
              // always executed
            })
            .finally(() => {
            });

        }

      };


    const handleClose = () => setValues(false);
    const handleShow = () => setValues(true);

    const list = () => (
        <Box
        sx={{ width: { xs: 'flex', md: 250 }}}
        role="presentation"
        onClick={handleClose}
        onKeyDown={handleClose}
      > 
        <List>
            {downloadItem.map((item:any) => (
            <>
            <ListItem key={item.key} disablePadding>
                <ListItemButton id={item.key + " Button"} onClick={handleClickItem}>
                <ListItemIcon>
                    {item.element}
                </ListItemIcon>
                <ListItemText primary={item.key} />
                </ListItemButton>
            </ListItem>
            <Divider light variant="inset" component="li" />            
            </>
            ))}
        </List>      
      
      </Box>


    )

    return (
        <>

          <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={values}
            onClose={handleClose}
            onOpen={handleShow}
            // sx={{ bcolor: 'black', }}
          >
           {list()}
          </SwipeableDrawer>
        </React.Fragment>

        </>
      );

  }