
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

import {
  Autocomplete,
  createFilterOptions,
  FormHelperText,
} from '@mui/material';

interface SetValuesProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  shortGridID: any;
  errors: any;
  setErrors: any;
}

interface liftNoOptionType {
    inputValue?: string;
    liftNo: string;
}
const liftNoFilter = createFilterOptions<liftNoOptionType>();

export default function LiftNoAutocompleteSelect(props: SetValuesProps) {
    const text_label = "Lift No.*"
    const id_name = "lift-No-autocomplete-select"
    // const initialValue: readonly liftNoOptionType[] = [
    // {liftNo: "1" }
    // ]    
    const {values, setValues, shortGridID, errors, setErrors} = props;
    const [value, setValue] = useState<liftNoOptionType | null>(null);

    // When the value in the parent component changes, update the local state
    useEffect(() => {
      setValue({ liftNo: values['liftNo'] });
    }, [values['liftNo']]);

        
    const initialValue: readonly liftNoOptionType[] =Array.from({ length: 10 }, (_, i) => ({ liftNo: shortGridID + "-L" + (i + 1).toString() }));


    // console.log(errors["liftNo"])
    // console.log(Boolean(errors["liftNo"]) )

    return (
        <Autocomplete
        value={value}
        onFocus = {() =>
          setErrors({
            ...errors,
            liftNo: undefined,
          })
          }
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              liftNo: newValue,
            });
            setValues({ ...values, ["liftNo"]: newValue})
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              liftNo: newValue.inputValue,
            });
            setValues({ ...values, ["liftNo"]: newValue.inputValue})

          } else if (newValue !== null) {
            setValue(newValue);
            setValues({ ...values, ["liftNo"]: newValue.liftNo})            
          }
        }}
        filterOptions={(options, params) => {
          const filtered = liftNoFilter(options, params);
    
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.liftNo);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              liftNo: `Add "${inputValue}"`,
            });
          }
    
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={id_name}
        options={initialValue}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          if (option.liftNo) {
            return option.liftNo;
          }
          // Default case
          return ''; // Return an empty string or another suitable default

        }}
        renderOption={(props, option) => <li {...props}>{option.liftNo}</li>}
        // sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField 
          {...params} 
          label={text_label} 
          error={Boolean(errors['liftNo'])} // Condition to show error
          helperText={errors['liftNo']} // Helper text for error
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">L</InputAdornment>,
          // }}
          
          />
        )}
        />


    
    )
    }
