import { 
    Modal, 
    Spinner,
    // Button 
  } from 'react-bootstrap';


export default function LoadingPage() {

    return (

    <Modal
    show={true}
    size="sm"
    // fullscreen
    backdrop="static"
    keyboard={false}
    centered
    >

    <Modal.Body className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" />
  </Modal.Body>
    
    </Modal>

    )




}