import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useMemo, useState, useEffect } from 'react';

interface SetValuesProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  proctorTableData: any;
}

export default function ProctorTestIDAutocompleteSelect(props: SetValuesProps) {

  const {values, setValues, proctorTableData} = props;


  const [value, setValue] = useState(values['proctorTestID'] === '' ? null : values['proctorTestID'] || null);



  // When the value in the parent component changes, update the local state
  useEffect(() => {
    setValue(values['proctorTestID'] === '' ? null : values['proctorTestID'] || null);
  }, [values['proctorTestID']]);


  return (
    <Autocomplete
      disablePortal
      disableClearable
      value={value} // Use the local state value
      id="proctor-test-id-autocomplete-select"
      // value={value}
      onChange={(event: any, newValue: any) => {
        setValue(newValue)
        setValues({ ...values, ["proctorTestID"]: newValue})
      }}
      // options={proctorTableData.length > 0 ? proctorTableData.map(obj => obj.testID) : []}
      options={proctorTableData.length > 0 ? proctorTableData.filter(obj => obj.materialType === values.materialType).map(obj => obj.testID) : []}

      getOptionLabel={(option:any) => `${option}`}
    //   sx={{ 
    //     width: 150,
    //     backgroundColor: 'white'

    // }}
      renderInput={(params) => <TextField 
        {...params} 
        label="Proctor Test ID" 
        InputProps={{
            ...params.InputProps,
            type: 'search',
          }}        
        />}
    />
  );
}
