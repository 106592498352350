import * as React from 'react';
import { useCallback, useMemo, useState, useEffect } from 'react';
import {
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

interface SetValuesProps {
    values: any;
    setValues: React.Dispatch<React.SetStateAction<any>>;
    confirmButtonText: string;
    errors: any;
    setErrors: any;
  }

export default function TestTypeToggleButtonGroup(props: SetValuesProps) {
  const {values, setValues, confirmButtonText, errors, setErrors} = props;
  // console.log(values);
  const [alignment, setAlignment] = React.useState(values['testType'] ?? '');
  // const handleChange = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string,
  // ) => {
  //   setAlignment(newAlignment);
  //   setValues({ ...values, "passOrRetest": newAlignment })
  // };

  // When the value in the parent component changes, update the local state
  useEffect(() => {
    setAlignment(values['testType'] );
    setErrors({
      ...errors,
      testType: undefined,
    })

  }, [values['testType']]);

  useEffect(() => {
    if (values['materialType'] !== "" && values['materialType'] !== "CCR") {
      setAlignment("Nuclear Density")
      setValues(prevValues => ({ ...prevValues, testType: "Nuclear Density" }));
    } else if (confirmButtonText === "Create"  &&  values['materialType'] === "CCR") {
      setAlignment("Drive Cylinder")
      setValues(prevValues => ({ ...prevValues, testType: "Drive Cylinder" }));

    }
  }, [values['materialType']]);


  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setValues(prevValues => ({ ...prevValues, testType: newAlignment }));


};

  return (
    <div>
      <ToggleButtonGroup
          size="large"
          color="primary"
          value={alignment}
          disabled={confirmButtonText === "Create" && values['materialType'] !== ""? false : true}
          exclusive
          onChange={handleChange}
          aria-label="test-type-toggle-button-group"
      >
        <ToggleButton 
        value="Nuclear Density"
        color="success"
        >
          Nuclear Density
          </ToggleButton>
        <ToggleButton 
        value="Drive Cylinder"
        color="secondary"
        disabled={values['materialType'] === "CCR"? false : true}
        >
          Drive Cylinder
          </ToggleButton>
        {/* <ToggleButton 
        value="Moisture Content"
        color="info"
        >
        Moisture Content
        </ToggleButton> */}
      </ToggleButtonGroup>
      {Boolean(errors["testType"]) && <FormHelperText error>{errors["testType"]}</FormHelperText>}  
    </div>

  );
}