import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

interface SetValuesProps {
    values: any;
    setValues: React.Dispatch<React.SetStateAction<any>>;
  }

export default function PassOrFailToggleButtonGroup(props: SetValuesProps) {
  const [alignment, setAlignment] = React.useState('');
  const {values, setValues} = props;

  // const handleChange = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newAlignment: string,
  // ) => {
  //   setAlignment(newAlignment);
  //   setValues({ ...values, "passOrRetest": newAlignment })
  // };
  const handleChange = (event, newAlignment) => {
    setValues(prevValues => ({ ...prevValues, passOrRetest: newAlignment }));
};

  return (
    <ToggleButtonGroup
        size="large"
        color="primary"
        value={values["passOrRetest"]}
        exclusive
        onChange={handleChange}
        aria-label="pass-or-retest-toggle-button-group"
    >
      <ToggleButton 
      value="Pass"
      color="success"
      >
        Pass
        </ToggleButton>
      <ToggleButton 
      value="Retest"
      color="error"
      >
        Retest
        </ToggleButton>
    </ToggleButtonGroup>
  );
}