
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

interface SetValuesProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  shortGridID: any;
  confirmButtonText: string;
  errors: any;
  setErrors: any;
}

interface testIDOptionType {
    inputValue?: string;
    testID: string;
}
const testIDFilter = createFilterOptions<testIDOptionType>();

export default function TestIDAutocompleteSelect(props: SetValuesProps) {
    const text_label = "Test ID*"
    const id_name = "test-ID-autocomplete-select"
    // const initialLiftNoValue: readonly testIDOptionType[] = [
    // {testID: "1" }
    // ]
    const {values, setValues, shortGridID, confirmButtonText, errors, setErrors} = props;
    const [value, setValue] = useState<testIDOptionType | null>(null);
    const [disabled, setDisabled] = useState(false);

    // When the value in the parent component changes, update the local state
    useEffect(() => {
      setValue({ testID: values['testID'] });
    }, [values['testID']]);

    useEffect(() => {
      if (values['testType'] === "" 
      // || confirmButtonText === "Update" 
      || values['liftNo'] === "") {
        setDisabled(true)

      } else {
        setDisabled(false)
      }
    }, [values['liftNo'], values['testType']]);


    // When values['testType'] changes, reset the value to an empty string
    useEffect(() => {
      if (confirmButtonText === "Create") {
        setValue({ testID: '' });
        setValues({ ...values, ['testID']: '' });
      }
    }, [confirmButtonText, values['testType']]);

    const initialLiftNoValue: readonly testIDOptionType[] = Array.from({ length: 10 }, (_, i) => {
      let testID = '';
      if (values['testType'] === 'Nuclear Density') {
          testID = values['liftNo'] + "-ND" + (i + 1).toString();

      } else if (values['testType'] === 'Drive Cylinder') {
          testID = values['liftNo'] + "-DC" + (i + 1).toString();
      } else if (values['testType'] === 'Moisture Content') {
          testID = values['liftNo'] + "-MC" + (i + 1).toString();
      } else {
          testID = values['liftNo'] + "-T" + (i + 1).toString();
      }
  
      return { testID };
  });
  // console.log(values)

    return (

        <Autocomplete
        // value={values["testID"]}
        value={value} // Use the local state value
        disabled={disabled}
        onFocus = {() =>
          setErrors({
            ...errors,
            testID: undefined,
          })
          }
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              testID: newValue,
            });
            setValues({ ...values, ["testID"]: newValue})
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              testID: newValue.inputValue,
            });
            setValues({ ...values, ["testID"]: newValue.inputValue})

          } else if (newValue !== null) {
            setValue(newValue);
            setValues({ ...values, ["testID"]: newValue})
            // console.log(newValue)
setValues({ ...values, ["testID"]: newValue.testID})
          }
        }}
        filterOptions={(options, params) => {
          const filtered = testIDFilter(options, params);
    
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.testID);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              testID: `Add "${inputValue}"`,
            });
          }
    
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={id_name}
        options={initialLiftNoValue}
        getOptionLabel={(option) => {

          // console.log('Option:', option); // Log the value to the console

          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          if (option.testID) {
            return option.testID;
          }
          // Default case
          return ''; // Return an empty string or another suitable default

        }}
        renderOption={(props, option) => <li {...props}>{option.testID}</li>}
        // sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField 
          {...params} 
          label={text_label}
          error={Boolean(errors['testID'])} // Condition to show error
          helperText={errors['testID']} // Helper text for error
           />
        )}
      />
    
    )
    }
