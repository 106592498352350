import axios from 'axios';
import React, { useCallback, useMemo, useState, useEffect, } from 'react';
import {
    MaterialReactTable,

    MRT_TableOptions,
    MRT_Cell,
    MRT_ColumnDef,
    MRT_Row,
} from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText ,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  AlertTitle,
  Alert,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import LoadingComponent from '../loading-page'


import {ProctorCompactionTest} from './data-definition'
import {CreateNewTestModal} from './create-new-modal'

// export type ProctorCompactionTest = {
//   testType: string;
//   testID: string;
//   maxDryDensity: number;
//   optimumMoistureContent: number;
//   wetDensity: number;
// };

export const ProctorCompactionTestData: ProctorCompactionTest[] = [
];


interface SetValuesProps {
  showDialog: any;
}

const ProctorInputTable = (props: SetValuesProps) => {

  const {showDialog} = props;
  const [isLoading, setIsLoading] = useState(false);


  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<ProctorCompactionTest[]>(() => ProctorCompactionTestData);

//  Alert:
  const [showAlert, setShowAlert] = useState(true);

  type AlertType = "error" | "info" | "success" | "warning" | undefined;
  const [inputAlertType, SetInputAlertType] = useState("success" as AlertType);
  const [inputAlertText, SetInputAlertText] = useState("Changes saved!");     

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 10000); // Set Alert to disappear after 10 seconds

    return () => {
      clearTimeout(timer); // Clear the timer if the component is unmounted before the timer finishes
    };
  }, [inputAlertText]);


//  Load data from DB:

  const API_URL = "/proctor_test/proctor_test_data/"

  
  useEffect(() => {
  if (showDialog===true) {
    // setIsLoading(true)

    axios.get(process.env.REACT_APP_BACKEND_URL + API_URL).then(function (response) {
      if (response.data) {
        // console.log(response.data)
        setTableData(response.data)
        SetInputAlertType("success")
        SetInputAlertText("Data successfully loaded from Cloud!")

      } else {
        setTableData([])
      }
      // setIsLoading(false)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      // setIsLoading(false)
      SetInputAlertType("error")
      SetInputAlertText(error)
    })
    .then(function () {
      // always executed
    });
    
  }
  }, [showDialog]);

  const handleCreateNewRow = async (values: ProctorCompactionTest) => {
    // console.log(values)
    // Insert new data row to DB:
    const newValues = values
    newValues["testType"] = "Proctor Compaction Test"
    newValues["_id"] = newValues["testType"].split(' ').join('-') + "-" + newValues["testID"]

    // console.log(newValues)

    try {
      // const db_url = "/proctor_test/proctor_test_data/"    
      const response = await axios.post(process.env.REACT_APP_BACKEND_URL + API_URL, newValues);
      // handle response
      if (response.data) {
      // console.log(response.data);
      SetInputAlertType("success")
      SetInputAlertText(`Proctor Test "${newValues['testID']}" has been added!`)
      }
    } catch (error) {
      // handle error
      console.error(error);
      SetInputAlertType("error")
      SetInputAlertText(error)
    }

// send new data row to table:
    tableData.push(newValues);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits: MRT_TableOptions<ProctorCompactionTest>['onEditingRowSave'] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {

        values["wetDensity"] = (1 + values["optimumMoistureContent"] / 100) * values["maxDryDensity"]
        values["wetDensity"] = values["wetDensity"].toFixed(1)

        //send/receive api updates here, then refetch or update local table data for re-render
// Update document to DB:
      const updateDBUrl = API_URL + values["_id"]
      const newValues = values
      if (newValues["_id"] !== '') {
        // setIsLoading(true)
        delete newValues["_id"]
        axios.put(process.env.REACT_APP_BACKEND_URL + updateDBUrl, newValues).then(function (response) {
          console.log(`Proctor Test "${newValues['testID']}" has been updated!`)
          // console.log(response.data)
          // setIsLoading(false)
          SetInputAlertType("info")
          SetInputAlertText(`Proctor Test "${newValues['testID']}" has been updated!`)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          // setIsLoading(false)
          SetInputAlertType("error")
          SetInputAlertText(error)
        })
        .then(function () {
          // always executed
        });

      }

// update data table in front view:
        tableData[row.index] = values;
        // console.log(tableData)
        // console.log(values)
        setTableData([...tableData]);
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

//  Delete data:

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);


  const handleDeleteRow = useCallback(
    (row: MRT_Row<ProctorCompactionTest>) => {
      // if (
      //   !window.confirm(`Are you sure you want to delete ${row.getValue('testID')}`)
      // ) {
      //   return;
      // }

      setDeleteDialogOpen(true);
      setSelectedRow(row);

//       //send api delete request here, then refetch or update local table data for re-render
// // Delete document from DB:
//       const newValues = tableData[row.index]
//       const deleteDBUrl = API_URL + newValues["_id"]
//       if (newValues["_id"] !== '') {
//         // setIsLoading(true)
//         delete newValues["_id"]
//         axios.delete(process.env.REACT_APP_BACKEND_URL + deleteDBUrl).then(function (response) {
//           console.log(`Proctor Test "${newValues['testID']}" has been deleted!`)
//           // console.log(response.data)
//           // setIsLoading(false)
//           SetInputAlertType("warning")
//           SetInputAlertText(`Proctor Test "${newValues['testID']}" has been deleted!`)
//         })
//         .catch(function (error) {
//           // handle error
//           console.log(error);
//           // setIsLoading(false)
//           SetInputAlertType("error")
//           SetInputAlertText(error)
//         })
//         .then(function () {
//           // always executed
//         });

//       }

//       tableData.splice(row.index, 1);
//       setTableData([...tableData]);
    },
    [],
  );

  const handleConfirmDelete = useCallback(
    () => {
      setDeleteDialogOpen(false);
      //send api delete request here, then refetch or update local table data for re-render
      const index = selectedRow.index;
// Delete document from DB:
      console.log(selectedRow)

      const newValues = tableData[index]
      console.log(newValues)
      const deleteDBUrl = API_URL + newValues["_id"]
      if (newValues["_id"] !== '') {
        // setIsLoading(true)
        delete newValues["_id"]
        axios.delete(process.env.REACT_APP_BACKEND_URL + deleteDBUrl).then(function (response) {
          console.log(`Proctor Test "${newValues['testID']}" has been deleted!`)
          // console.log(response.data)
          // setIsLoading(false)
          SetInputAlertType("warning")
          SetInputAlertText(`Proctor Test "${newValues['testID']}" has been deleted!`)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          // setIsLoading(false)
          SetInputAlertType("error")
          SetInputAlertText(error)
        })
        .then(function () {
          // always executed
        });

      }

// delete row in data table in front view:
      if (index > -1) {
        tableData.splice(index, 1);
        setTableData([...tableData]);
      }
    },
    [tableData, selectedRow],
  );

  const handleCloseDeleteDialog = useCallback(
    () => {
      setDeleteDialogOpen(false);
    },
    [],
  );


  //  Data Validation:
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});
  const validateRequired = (value: string) => !!value.length;
  const validateTestID = (testID: string) => tableData.some(item => item.testID?.toLowerCase() === testID?.toLowerCase());
  const validateMaximumDryDensity = (maxDryDensity: number) => maxDryDensity >= 0 && maxDryDensity <= 150;
  const validateOptimumMoistureContent = (optimumMoistureContent: number) => optimumMoistureContent >= 0 && optimumMoistureContent <= 50;

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<ProctorCompactionTest>,

    ): MRT_ColumnDef<ProctorCompactionTest>['muiEditTextFieldProps'] => {
      
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onChange: (event) => {


        },
        onBlur: (event) => {
          if (validationErrors[event.target.name]) {
            // If there's an error for this field, clear it when the field is modified
            setValidationErrors({ ...validationErrors, [event.target.name]: null });
          }

          // console.log(cell.id)
          const isRequired = validateRequired(event.target.value)
//  Validate required fields:
          if (!isRequired) {
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }

//  Validate if testID is unique:
          if (cell.column.id === 'testID') {
            const isUnique = validateTestID(cell.column.id )
            // console.log(isUnique)
            if (!isUnique) {
              setValidationErrors({
                ...validationErrors,
                [cell.id]: 'This test ID already exists',
              });
            } else {
              //remove validation error for cell if valid
              delete validationErrors[cell.id];
              setValidationErrors({
                ...validationErrors,
              });
            }
          } 

  //  Validate if maxDryDensity is in the normal range:
          if (cell.column.id === 'maxDryDensity') {
            const isValid = validateMaximumDryDensity(parseFloat(event.target.value))
            console.log(isValid)
            if (!isValid) {
              setValidationErrors({
                ...validationErrors,
                [cell.id]: 'This value is not in the normal range, please double check your input!',
              });
            } else {
              //remove validation error for cell if valid
              delete validationErrors[cell.id];
              setValidationErrors({
                ...validationErrors,
              });
            }
          } 

  //  Validate if optimumMoistureContent is in the normal range:
          if (cell.column.id === 'optimumMoistureContent') {
            const isValid = validateOptimumMoistureContent(parseFloat(event.target.value))
            console.log(isValid)
            if (!isValid) {
              setValidationErrors({
                ...validationErrors,
                [cell.id]: 'This value is not in the normal range, please double check your input!',
              });
            } else {
              //remove validation error for cell if valid
              delete validationErrors[cell.id];
              setValidationErrors({
                ...validationErrors,
              });
            }
          }

          console.log(validationErrors)
        },
      };
    },
    [validationErrors],
  );

  const columns = useMemo<MRT_ColumnDef<ProctorCompactionTest>[]>(
    () => [
      {
        accessorKey: '_id',
        header: '_id',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        visibility: false,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          sx: {display: 'none'},
        }),
      },
      {
        accessorKey: 'testType',
        header: 'testType',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          sx: {display: 'none'},
        }),
      },
      {
        accessorKey: 'testID',
        header: 'Test ID',
        enableColumnOrdering: true,
        enableEditing: false, //disable editing on this column
        enableSorting: true,
        size: 80,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        accessorKey: 'materialType',
        header: 'Material Type',
        size: 80,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: false, 
        enableSorting: true,
        muiEditTextFieldProps: ({ cell, row }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string',
        }),
      },
      {
        accessorKey: 'maxDryDensity',
        header: 'Maximum Dry Density (PCF)',
        size: 300,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'optimumMoistureContent',
        header: 'Optimum Moisture Content (%)',
        size: 350,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'wetDensity',
        header: 'Wet Density (PCF)',
        size: 300,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'number',
          disabled: true,
        }),
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 300,
        enableColumnOrdering: true,
        enableClickToCopy: true,
        enableEditing: true, 
        enableSorting: true,
        muiEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: 'string',
        }),
      },
    ],
    [getCommonEditTextFieldProps],
  );


  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editDisplayMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        initialState={{ columnVisibility: { _id: false , testType: false} }} 
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => {
                table.setEditingRow(row)
              }}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => {
                // console.log(tableData[row.index])
                handleDeleteRow(row)
                }}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <div>
            {showAlert && 
              <Alert 
            severity={inputAlertType}
            sx={{ 
              p: '0rem', 
              m: '1.25rem', 
              backgroundColor: 'transparent',
          }} 
            // color={inputAlertType}
            style={ inputAlertText!=='' ? {display:"flex"} : {display:"none"}}
            >
                <AlertTitle>{inputAlertText}</AlertTitle>
                {/* {error} */}
              </Alert>       
              }

            <Button
              color="success"
              onClick={() => setCreateModalOpen(true)}
              variant="contained"
            >
              Create New Test
            </Button>
          </div>

           
        )}
      />
      <CreateNewTestModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        tableData= {tableData}
      />

      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete "${selectedRow && selectedRow.getValue('testID')}"?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="warning" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>



      {isLoading && (
              <LoadingComponent/>
            )}
    </>
  );
};

export default ProctorInputTable;