import * as React from 'react';
// import ToggleButton from '@mui/material/ToggleButton';
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useCallback, useMemo, useState, useEffect } from 'react';

import {
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

interface SetValuesProps {
    values: any;
    setValues: React.Dispatch<React.SetStateAction<any>>;
    confirmButtonText: string;
    errors: any;
    setErrors: any;
  }

export default function MaterialTypeToggleButtonGroup(props: SetValuesProps) {
  const {values, setValues, confirmButtonText, errors, setErrors} = props;
  // console.log(values);
  const [alignment, setAlignment] = React.useState(values['materialType'] ?? '');

  // When the value in the parent component changes, update the local state
  useEffect(() => {
    setAlignment(values['materialType'] );
    setErrors({
      ...errors,
      materialType: undefined,
    })

  }, [values['materialType']]);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setValues(prevValues => ({ ...prevValues, materialType: newAlignment }));



};

  return (

    <div>
      <ToggleButtonGroup
          size="large"
          color="primary"
          value={alignment}
          disabled={confirmButtonText === "Create" ? false : true}
          exclusive
          onChange={handleChange}
          aria-label="test-type-toggle-button-group"
          // onFocus = {() =>
          //   setErrors({
          //     ...errors,
          //     date: undefined,
          //   })
          //   }
      >
        <ToggleButton 
          value="Structure Fill"
          color="secondary"
          >
            Structure Fill
        </ToggleButton>
        <ToggleButton 
          value="Soil Cover"
          color="success"
          >
            Soil Cover
        </ToggleButton>
        <ToggleButton 
          value="CCR"
          color="info"
          >
          CCR
        </ToggleButton>
      </ToggleButtonGroup>
        {Boolean(errors["materialType"]) && <FormHelperText error>{errors["materialType"]}</FormHelperText>}  

    </div>
  
  );
}