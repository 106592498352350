import {
    Navigate ,
    useLocation
  } from "react-router-dom";

interface Props {

  children: React.ReactElement;
}

export const setToken = (token:any) =>{
    // set token in localStorage
    localStorage.setItem('GSToken', token)
}
export const fetchToken = () =>{
    // fetch the token
    return localStorage.getItem('GSToken')
}
export function RequireToken(props: Props) {
    let {children} = props;
    let auth = fetchToken()
    let location = useLocation();
  
    if (!auth) {
      
      return <Navigate to="/main" state={{ from: location }} />;
    }
  
    return children;
}
